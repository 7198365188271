import React from "react";
import * as Realm from "realm-web";
// import { baseUrl, dataSourceName, navoreDatabase, privateProfileCollection, publicProfileCollection, producerAccessCollection, producerProfileCollection } from "./realm.json";
import realmjsondata from "./realm.json";
import { BSON } from "realm-web";
import { t } from "i18next";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { startResumableUpload } from "./firebase";

function createRealmApp(id) {
  console.log("CREATE REALM APP: ", id)
  let url = realmjsondata.baseUrl
  return new Realm.App({ id,  url});
}


const RealmAppContext = React.createContext(null);


async function getValidAccessToken(realmApp) {

  // Guarantee that there's a logged in user with a valid access token
  if (!realmApp.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    
    const credentials = Realm.Credentials.anonymous();
    await realmApp.logIn(credentials);

  } else {

    const { exp } = jwt_decode(realmApp.currentUser.accessToken);
    const isExpired = Date.now() >= exp * 1000;
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    if (isExpired) {
      // To manually refresh the user's expired access token, we refresh their custom data
      await realmApp.currentUser.refreshCustomData();
    }
  }
  
  console.log("ACCESS TOKEN - REALMAPP ", realmApp.currentUser.accessToken)
  return realmApp.currentUser.accessToken;
}


function createApolloClient(realmApp){


    console.log("CREATE APOLLO CLIENT: ", realmApp)
    const graphqlUri = `${realmjsondata.baseUrl}/api/client/v2.0/app/${realmApp.id}/graphql`;

    const client = new ApolloClient({
      link: new HttpLink({
        uri: graphqlUri,
        // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
        // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
        // access token before sending the request.
        fetch: async (uri, options) => {
          const accessToken = await getValidAccessToken(realmApp);
          options.headers.Authorization = `Bearer ${accessToken}`;
          return fetch(uri, options);
        },
      }),
      cache: new InMemoryCache(),
      connectToDevTools: true

    });

    return client
}


export function RealmAppProvider({ appId, children }) {
  // Store Realm.App in React state. If appId changes, all children will rerender and use the new realmApp.
  const [realmApp, setRealmApp] = React.useState(createRealmApp(appId));
  const [apolloClient, setApolloClient] = React.useState(createApolloClient(realmApp));


  React.useEffect(() => {
    let app = createRealmApp(appId)
    setRealmApp(app);

    let aClient = createApolloClient(app)
    setApolloClient(aClient)

  }, [appId]);



  // Store the app's current user in state and wrap the built-in auth functions to modify this state
  const [currentUser, setCurrentUser] = React.useState(realmApp.currentUser);
  const [customData, setCustomData] = React.useState(undefined);
  const [view, setView] = React.useState('');


  function updateViewLabel(newView){
    setView(newView)
  }


  // Wrap the base logIn function to save the logged in user in state
  const logInEmail = React.useCallback(

    async (credentials) => {

      await realmApp.logIn(credentials);
      
      //set provider type as well as

      setCurrentUser(realmApp.currentUser);
      refreshCustomUserData()
    },
    [realmApp]
  );



  const registerEmail = React.useCallback(
    async (email, password, producer, name, producerName, producerEmail) => {

      await realmApp.emailPasswordAuth.registerUser(email, password)
      await logInEmail(Realm.Credentials.emailPassword(email, password));

      //set provider type as well as

      setCurrentUser(realmApp.currentUser);
      refreshCustomUserData()

      let id = realmApp.currentUser.id
      let producerIds = []
      var producerId = ""

      console.log("Is Producer: ", producer)
      if(producer){
        console.log("Creating Producer Profile")
        //create and get producer_id
        producerId = await saveProducerAccessData({owners:[id], all_associates:[id], deliveries:{edit:[],read:[]},labels:{edit:[],read:[]}, profile:{edit:[],read:[]},  wholesalers:[], products:{edit:[],read:[]}, lists:{edit:[],read:[]}, orders:{edit:[],read:[]}, account_settings:{edit:[],read:[]}, photos:{edit:[],read:[]}, quantity:{edit:[],read:[]}})

        if(producerId == undefined){
          return
        }
        producerIds.push(producerId)
      }

      await savePrivateProfile({_id:BSON.ObjectID(id), _partition:id, location:{type:"", coordinates:[]}, 
      name:name, phone_number:"" , email: email, producer_ids:producerIds, notifications_token:"", cover_image:"", 
      address:"", favorite_recipes:[], favorite_products:[], favorite_producers:[], address:"", city:'', state:"", zip:""})

      await savePublicProfile({_id:BSON.ObjectID(id),_partition:id, 
        name:name, cover_image:"", email:email})


      if(producer){
        await saveProducerProfile(producerId, producerName, producerEmail)
      }

    },
    [realmApp]
  );



  async function savePrivateProfile(data){
    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);

    try {
      const result = await mdb.db(realmjsondata.navoreDatabase).collection(realmjsondata.privateProfileCollection).insertOne(data);

    } catch (err) {
        console.error(err);
    }
  }

  async function savePublicProfile(data){
    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);

    try {
      const result = await mdb.db(realmjsondata.navoreDatabase).collection(realmjsondata.publicProfileCollection).insertOne(data);
    } catch (err) {
        console.error(err);
    }
  }

  async function saveProducerAccessData(data){

    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);
    const access_collection = mdb.db(realmjsondata.navoreDatabase).collection(realmjsondata.producerAccessCollection)

    try {
      const result = await access_collection.insertOne(data)
      let id = result.insertedId
      console.log("Created Id: ", id.toString())

      const filter = { _id: id };
      const options = { upsert: false };
      const updateDoc = {
        $set: {
            _producer_id: id.toString(),
            _partition: id.toString()
        },
      };
      await access_collection.updateOne(filter, updateDoc, options);


      return id.toString()

    } catch (err) {
        console.error(err);
        return undefined
    }
  }

  async function saveProducerProfile(id, name, email){

    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);
    const producer_profile_collection = mdb.db(realmjsondata.navoreDatabase).collection(realmjsondata.producerProfileCollection)

    try {

      await producer_profile_collection.insertOne({_id:BSON.ObjectID(id), _partition:id, name:name, cover_image:"", 
        timezone:'pst', location:{type:"Point", coordinates:[0,0]}, 
        email:email, rating:0, total_reviews:0, offers_delivery:false, offers_pickup: false, all_inventory:[],
        about_land:"", about_produce:"", url:"", producer_story:"", validated:false, available_categories:[], active_media:[], active_plans:[]
      })


    } catch (err) {
        console.error(err);
        return undefined
    }
  }


 
  async function refreshCustomUserData(){

    if(!realmApp.currentUser) return undefined
    const customUserData =  await realmApp.currentUser.refreshCustomData()
    console.log("Custom User Data", customUserData)

    setCustomData(customUserData)
    return customUserData
  }

  const logInAnonymous = React.useCallback(

    async () => {
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await realmApp.logIn(credentials);

        setCurrentUser(realmApp.currentUser);
      } catch (err) {
        console.error("Failed to log in", err.message);
      }
    },
    [realmApp]
  );

  const fullyRemoveUser = React.useCallback(async () => {

    console.log("Attempting to Remove User", currentUser)
    try{
      await realmApp.removeUser(currentUser);
    }catch(err){
      console.log("Error Removing User", err)
      try{
        await currentUser?.logOut();
      }catch(e){
        console.log("Error Logging Out User", e)
      }
    }

    console.log("Setting Anon false and currentUser ", realmApp.currentUser)
    setCurrentUser(realmApp.currentUser);

  }, [realmApp, currentUser]);





  // Override the App's currentUser & logIn properties + include the app-level logout function
  const realmAppContext = React.useMemo(() => {
    return { ...realmApp, apolloClient, currentUser, logInEmail, registerEmail, logInAnonymous, fullyRemoveUser, refreshCustomUserData, customData, updateViewLabel, view };
  }, [realmApp, apolloClient, currentUser, logInEmail,registerEmail, logInAnonymous, fullyRemoveUser, refreshCustomUserData, customData, updateViewLabel, view ]);


  return (
    <RealmAppContext.Provider value={realmAppContext}>
      <ApolloProvider client={apolloClient}>
        {children}
      </ApolloProvider>
    </RealmAppContext.Provider>
  );

}



export function useRealmApp() {
  const realmApp = React.useContext(RealmAppContext);
  if (!realmApp) {return undefined}
  return realmApp;
}
