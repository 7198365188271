import React from "react";
import { createRoot } from 'react-dom/client';
import './App.scss';
import AppWithRealm from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<React.StrictMode>
  <AppWithRealm />
</React.StrictMode>);


serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
