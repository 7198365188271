
import React, { useEffect, useState } from 'react' 

import "../components/Footer/index.scss"

import AutoBind from 'auto-bind'
import { useSSR } from 'react-i18next'
import { init } from 'i18next'


import {ReactComponent as Download} from "../svg/download.svg"
import {ReactComponent as Logo} from "../svg/logo.svg"
import {ReactComponent as Chevron} from "../svg/chevron.svg"
import {ReactComponent as Facebook} from "../svg/facebook.svg"
import {ReactComponent as Instagram} from "../svg/instagram.svg"
import {ReactComponent as Linkedin} from "../svg/linkedin.svg"


 export default function Footer(props){


        const [active, setActive] = useState(0)
        const [main, setMain] = useState(null)

        const [DOM, setDOM] = useState({
            el:null,
            desp: null
          }
        )


        useEffect(() => {

            console.log("MAIN", props)
            setMain(props)
            const fetchDOMElements = () =>{
                const newDOM = {
                    el:document.querySelector('.footer'),
                    desp: document.querySelectorAll('.Desp')
                  }
              
                setDOM(newDOM)
            }
            

         
            fetchDOMElements()
        
        }, []);

        useEffect(() => {

            const initEvents = () => {
                
                // if(this.main.isTouch){
                    // for(let el of DOM.desp){
                    //     el.onclick = async() => {
                    //         if(el.classList.contains('act')){
                    //             el.classList.remove('act')
                    //             el.querySelector('.DespHold').style.height = 0
                    //         }else{
                    //             el.classList.add('act')
                    //             el.querySelector('.DespHold').style.height = el.querySelector('.DespBox').clientHeight + 'px'
                                
                    //         }
                    //     }
                        
                    
                    // }
                // }
                
            }
            initEvents()

        }, [DOM])


        return(
            <footer className="footer">
            <div className="c-vw content">
                <div className="content_top">
                    <div className="content_top_l"> 
                    '<a className="footer_logo" aria-label="Návore">
                            <Logo/>
                            </a>
                            <a className="Abtn Abtn-s">
                            <div className="Abtn_i"> 
                            <Download/>
                            </div>
                            <div className="Abtn_t">Download app</div>
                        </a></div>
                    <div className="content_top_r opts">
                        <div className="opts_col">
                            <div className="opts_title">Navore for producers </div>
                            <div className="opts_list"> <a className="Abtn-simple" href="">Create your business account </a><a
                                    className="Abtn-simple" href="">Explore Navore features </a><a className="Abtn-simple"
                                    href="">Set up an appointment with sales </a><a className="Abtn Abtn-s Abtn-inv" href="#">
                                    <div className="Abtn_t">Producer log in </div>
                                </a></div>
                        </div>
                        <div className="opts_col">
                            <div className="opts_title">About Navore</div>
                            <div className="opts_list"> <a className="Abtn-simple" href="">Who we are </a><a
                                    className="Abtn-simple" href="">Help center </a><a className="Abtn-simple" href="">Contact
                                </a></div>
                        </div>
                        <div className="opts_col Desp">
                            <div className="opts_title DespClick">Legal<span className="opts_title_arrow"> 
                            <Chevron/>
                            </span></div>
                            <div className="DespHold">
                                <div className="opts_list DespBox"><a className="Abtn-simple" href="">Privacy policy </a><a
                                        className="Abtn-simple" href="">Legal Terms</a><a className="Abtn-simple"
                                        href="">Cookies </a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_bottom">
                    <div className="content_bottom_l">
                        <div className="content_copy">2023©Návore market</div>
                    </div>
                    <div className="content_bottom_r">
                        <div className="content_lang"> <label className="lang_label" htmlFor="langselect">Select
                                Language*</label><select className="lang_select" name="langselect" id="langselect">
                                <option value="english">English</option>
                                <option value="spanish">Spanish </option>
                                <option value="french">French </option>
                            </select>
                            <div className="lang_arrow">
                                <Chevron/>
                                </div>
                        </div>
                        <div className="content_social">
                            <div className="content_social_title">Follow us</div>
                            <div className="content_social_links"><a className="Abtn Abtn-social Abtn-inv" href="#">
                                    <div className="Abtn_i"> 
                                    <Facebook/>
                                    </div>
                                </a><a className="Abtn Abtn-social Abtn-inv" href="#">
                                    <div className="Abtn_i"> 
                                        <Instagram/>
                                    </div>
                                </a><a className="Abtn Abtn-social Abtn-inv" href="#">
                                    <div className="Abtn_i"> 
                                        <Linkedin/>
                                    </div>
                                </a></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        )
}
