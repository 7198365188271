
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { useRealmApp } from "../../RealmApp";
import { useNavore } from "../../hooks/useNavore";

// import {ReactComponent as WalkMini} from "../../svg/walkmini.svg"
import {ReactComponent as PickupMini} from "../../svg/pickupmini.svg"
import {ReactComponent as MarkerMini} from '../../svg/markermini.svg';

import { calculateNextAvailablePickup, getAvailableCategoriesString, makeKey } from '../../utils';


const PickupSearchItem = (props) =>{

    return(
        <a className="pickupsearch_blocks_el el">
                
            <div className="el_image"><img src={props.image} /></div>
            <h5 className="el_t">{props.name}</h5>
        </a>)
}

export default function Pickupsearch(props){

    let {t} = useTranslation()

    let catString = getAvailableCategoriesString(props)
    let nextPickup = calculateNextAvailablePickup(props)
    let products = props.all_inventory
    let minimum_delivery = props.minimum_delivery_price

    let distance_away = props.time_away ? props.time_away : 3

    useEffect(() => {

    }, [])

    return(
        <article className="pickupsearch"><a className="pickupsearch_main">
        <div className="pickupsearch_main_top">
            <img src={props.cover_image} />
            <h3 className="pickupsearch_main_t">{props.name}</h3>
        </div>
        <div className="pickupsearch_main_bottom bottom">
            <h4 className="bottom_t">{catString}</h4>
            <div className="bottom_els">
                <div className="bottom_els_line">
                    <div className="i">
                     <PickupMini/>

                    </div>
                    <p className="t">{nextPickup}</p>
                </div>
                <div className="bottom_els_line pickupline">
                    <div className="i">
                    <PickupMini/>
                        
                    </div>
                    <p className="t">${minimum_delivery} order min</p>
                </div>
                <div className="bottom_els_line">
                    <div className="i">
                    <MarkerMini/>

                        </div>
                    <p className="t">Kengsinton Market · <span> {distance_away} min</span></p>
                </div>
            </div>
        </div>
    </a>
    <div className="pickupsearch_blocks">
        <div className="pickupsearch_blocks_hold">
            {products && products.map((item, index) => {
                let key = makeKey()
                item.key = key + index
                return(<PickupSearchItem {...item}/>)
            })}
        </div>
    </div>
</article>
    )
}
