import React, { useLayoutEffect,useEffect, useState} from 'react'
import GMap from './components/Map'

import './scss/base/normalize.scss'
import './scss/base/generics.scss'
import './scss/base/swiper.scss'
import './scss/theme/scroll.scss'
import './scss/theme/main.scss'
// import './scss/theme/fonts.scss'


import './scss/theme/uikit.scss'


import './atoms/close.scss'
import './atoms/btn.scss'
import './atoms/link.scss'
import './atoms/qty.scss'
import './atoms/tip.scss'
import './atoms/radio.scss'
import './atoms/check.scss'
import './atoms/bubble.scss'


import './components/Modal/index.scss'

import './components/Cart/index.scss'
import './components/Cart/producer.scss'

import './components/Alert/index.scss'

import './components/Search/map.scss'
import './components/Search/deliver.scss'
import './components/Search/pickup.scss'
import './components/Search/shop.scss'
import './components/Search/filters.scss'
import './components/Search/cats.scss'
import './components/Search/top.scss'
import './components/Search/searchbar.scss'
import './components/Search/bubble.scss'
import './components/Search/index.scss'


import browser from './start/browser.js'

import Search from './Search'

import { gsap } from 'gsap'
import FontFaceObserver from 'fontfaceobserver'
import Loader from './components/Loader'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RealmAppProvider, useRealmApp } from './RealmApp'
import realmjsondata from './realm.json'

import Nav from './basic/Nav';
import Footer from './basic/Footer'
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'

import LogRocket from 'logrocket';
LogRocket.init('z6rxcj/navore');


// let lenisOptions = {
//   wheelEventsTarget:document.documentElement,
//   lerp:.05,
//   // duration:.6,
//   normalizeWheel:false,
// }

export default function AppWithRealm() {

  return (
      <RealmAppProvider appId={realmjsondata.appId}>
        <ReactLenis root>
          <App />
        </ReactLenis>
      </RealmAppProvider>
  );
}



function App() {
  const { currentUser, logOut } = useRealmApp();

  const [speed, setSpeed] = useState(0)
  const [loader, setLoader] = useState(null)
  const [loaded, setLoaded] = useState(0)
  const [scrollBar, setScrollBar] = useState(null)
  const [page, setPage] = useState(null)

  const [global, setGlobal] = useState(browser.browserCheck())

  const lenis = useLenis(({ scroll }) => {
    // called every scroll
    // scrollCheck()
  })


  //GLOBAL WINDOW AND TOUCH/SCROLL CONFIG
  useEffect(() => {
    browser.revCheck()

    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }
    
    
    document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
    document.documentElement.classList.add('lenis-stopped')
    
    

    //SIZES :
    // --ck_hscr :  No bars screen height
    // --ck_hvar :  Variable screen height by browser state
    // --ck_hmin :  screen height with expanded bars ( smaller size )
    // 100vh     :  screen height with Contracted bars ( bigger size, dont need var )
    
    // EL TIPO DE INCREMENTO PARA TAMAÑOS POR ENCIMA DE DISEÑO
    let newGlobal = global
    newGlobal.design = {
      L:{
        w : 1440,
        h : 800,
        multi:1,
        total:0,
        ratio:5.56,
        wide:((window.innerHeight*10)/window.innerWidth).toFixed(2),
        
      },
      P:{
        w:375,
        h:640,
        multi:.5,
        total:0
      }
    }
    
    // REM CALCULATOR
    newGlobal.design.L.total = ( ( newGlobal.design.L.w / window.innerWidth ) * 10 )
    newGlobal.design.L.total = 10 - ((10 - newGlobal.design.L.total) * newGlobal.design.L.multi)
    newGlobal.design.L.total = Math.min(10,newGlobal.design.L.total)
    newGlobal.design.P.total = ( ( newGlobal.design.P.w / window.innerWidth ) * 10 )
    newGlobal.design.P.total = 10 - ((10 - newGlobal.design.P.total) * newGlobal.design.P.multi)
    newGlobal.design.P.total = Math.min(10,newGlobal.design.P.total)

    setGlobal(newGlobal)
    
    //REM PER WIDE
    // global.design.L.total *=  Math.min(1,(global.design.L.wide/global.design.L.ratio)*1.05)
    
    document.documentElement.style.setProperty("--ck_multiL", newGlobal.design.L.total)
    document.documentElement.style.setProperty("--ck_multiP", newGlobal.design.P.total)
    
    
    if(newGlobal.isTouch == 1){
    
      document.documentElement.style.setProperty("--ck_hscr", window.screen.height+'px')
      document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
      document.documentElement.style.setProperty("--ck_hmin", document.documentElement.clientHeight+'px')
    }else{

      document.documentElement.style.setProperty("--ck_hscr", window.innerHeight+'px')
      document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
      document.documentElement.style.setProperty("--ck_hmin", window.innerHeight+'px')
    }
    createScrollBar()
    setGlobal(global)

  }, [])


  
  useEffect(() =>{
    // construct()
    console.log("Current User: ", currentUser)
  },[currentUser, logOut])


  function construct () {

    if(!global) return
    // AutoBind(this)

    let content = document.querySelector('#content')
    let main = global
    main.screen = {
      w:window.innerWidth,
      h:window.innerHeight
    }

    let FR = 1e3 / 60

    let speed = 0
    let wheeling = 0
    let isclick = 0
    let searching = 0
    let loaded = 0
    let scry = 0

    let resizevar = ''
    let url = window.location.pathname

    let values = {
      speed:speed,
      wheeling:wheeling,
      isclick:isclick,
      searching:searching,
      loaded:loaded,
      scry:scry,
      resizevar:resizevar,
      url:url,
      FR:FR,
      main:main,
      content:content


    }


    // initApp(values)
    
  }







  function scrollCheck(){



    if(!global || !lenis){return}

    if(global.isTouch == 0){

      scrollFnTouch()
      
    }else{
      
      scrollFn()

    }
  }

  const scrollFnTouch = ()=> {

    setSpeed(lenis.velocity)
    

    if(page){

      page.scroll.target = lenis.targetScroll
      page.scroll.current = lenis.animatedScroll
      page.scroll.last = lenis.animatedScroll

      scrollBar.style.height = (100*lenis.animatedScroll / page.scroll.limit)+'%'

      page.animIosScroll()
    }

    if(speed < 0){
      document.documentElement.classList.add('scroll-up')
    }
    else if(speed > 0){
      document.documentElement.classList.remove('scroll-up')

    }

    if(lenis.targetScroll == 0){
      document.documentElement.classList.remove('scroll-start')
    }
    else if(lenis.targetScroll > 0){
      document.documentElement.classList.add('scroll-start')

    }
  }

  const scrollFn = () =>{

    setSpeed(lenis.velocity)
   
    if(!page){
      return false
    }

    if(page.scroll.target > lenis.targetScroll){
      document.documentElement.classList.add('scroll-up')
    }
    else if(page.scroll.target < lenis.targetScroll){
      document.documentElement.classList.remove('scroll-up')
    }

    if(lenis.targetScroll == 0){
      document.documentElement.classList.remove('scroll-start')
    }
    else if(lenis.targetScroll > 0){
      document.documentElement.classList.add('scroll-start')
    }
    
    if(page){

      page.scroll.target = lenis.targetScroll
      
      page.animIosScroll()
    }
  }

    

  const createScrollBar = () =>{

    const scrollBar = document.createElement('div')
    scrollBar.className = 'scrBar'
    scrollBar.insertAdjacentHTML('afterbegin','<div class="scrBar_prg"></div>')
    
    document.querySelector('body').appendChild(scrollBar)


    setScrollBar(document.querySelector('.scrBar_prg'))
  }


  function update(time) {

    if(lenis){
      lenis.raf(time)
    }

    // if (page) {
    //   page.update(this.speed)
    // }   

    window.requestAnimationFrame(this.update)
  }


  function onResize () {

    // REM CALCULATOR
    let newGlobal = global
    newGlobal.design.L.total = ( ( newGlobal.design.L.w / window.innerWidth ) * 10 )

    newGlobal.design.L.total = 10 - ((10 - newGlobal.design.L.total) * newGlobal.design.L.multi)

    newGlobal.design.L.total = Math.min(10,newGlobal.design.L.total)


    newGlobal.design.P.total = ( ( newGlobal.design.P.w / window.innerWidth ) * 10 )

    newGlobal.design.P.total = 10 - ((10 - newGlobal.design.P.total) * newGlobal.design.P.multi)

    newGlobal.design.P.total = Math.min(10,newGlobal.design.P.total)


    document.documentElement.style.setProperty("--ck_multiL", newGlobal.design.L.total)
    document.documentElement.style.setProperty("--ck_multiP", newGlobal.design.P.total)
    // document.documentElement.style.setProperty("--ck_tochol", (((window.innerWidth/1440) - 1)*.02+'vw'))
    // document.documentElement.style.setProperty("--ck_tochop", (((window.innerWidth/375) - 1)*.5+'vw'))
    if (newGlobal.isTouch){
      document.documentElement.style.setProperty("--ck_hscr", window.screen.height+'px')
      document.documentElement.style.setProperty("--ck_hmin", document.documentElement.clientHeight+'px')
      gsap.set(document.documentElement,{"--ck_hvar":window.innerHeight+"px"})
      
      var isTouch = /Android|iPhone|iPad|iPod|Bla--ckBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
      if(!isTouch){
        window.location.reload()
      }
      
    
    }
    else{
      document.documentElement.style.setProperty("--ck_hscr", window.innerHeight+'px')
      document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
      document.documentElement.style.setProperty("--ck_hmin", window.innerHeight+'px')
      // var isTouch = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || window.innerWidth < 1181;

      var isTouch = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
      
      if(isTouch){
        window.location.reload()
      }
    }

    //REFRESH ELEMENTS DATA SIZES
    newGlobal.screen.w = window.innerWidth
    newGlobal.screen.h = window.innerHeight
  
    setGlobal(newGlobal)
  }


  return (
    <div>
      <Router>
        <Nav />
        <Routes id="content">
            <Route path="/home" element={<Search />}/>
            <Route path="/" element={<Search />}/>
            {/* 
             <Route path="/orders" element={currentUser && <Orders/>}/>
            <Route path="/store" element={currentUser && <MyStore/>}/>
            <Route path="/account" element={currentUser && <Account/>}/>
            <Route path="/settings" element={currentUser && <StoreSettings/>}/>
            <Route path="/profile" element={currentUser && <Store/>}/>
            <Route path="/media" element={currentUser && <MediaGallery/>}/>
            <Route path="/users" element={currentUser && <Users/>}/>
            <Route path="/products" element={currentUser && <Products/>}/>
            <Route path="/wholesalers" element={currentUser && <WholesaleGroups/>}/>
            <Route path="/wholesalers/:id" element={currentUser && <WholesaleGroupMembers/>}/>
            <Route path="/customers" element={currentUser && <Customers/>}/>
            <Route path="/customerlist" element={currentUser && <CustomerList/>}/>
            <Route path="/wholesalelist" element={currentUser && <Wholesale/>}/>
            <Route path="/editproduct/:id" element={currentUser && <EditProduct/>}/>
            <Route path="/editproduct" element={currentUser && <EditProduct/>}/>
            <Route path="/recipes" element={currentUser && <Recipes/>}/>
            <Route path="/editrecipe/:id" element={currentUser && <EditRecipe/>}/>
            <Route path="/editrecipe" element={currentUser && <EditRecipe/>}/>
            <Route path="/help" element={currentUser && <HelpCenter/>}/>
            <Route path="/plans" element={currentUser && <Plans/>}/>
            <Route path="/edit" element={currentUser && <AddImage/>}/>
            <Route path="/upload" element={currentUser && <AddImage/>}/>
            <Route path="/editplan/:type/:id" element={currentUser && <EditPlan/>}/>
            <Route path="/editplan/:type" element={currentUser && <EditPlan/>}/>
            <Route path="/inbox" element={currentUser && <Inbox/>}/>
            <Route path="/tools" element={currentUser && <Tools/>}/>
            <Route path="/schedules" element={currentUser && <ViewSchedules/>}/>
            <Route path="/producer/:id" element={<ProducerProfile/>}/>
            <Route path="/customer/:id" element={<CustomerProfile/>}/>
            <Route path="/login" element={<WelcomePage/>}/>
            <Route path="/reset" element={<ResetPage/>}/>
            <Route path="/sendreset" element={<SendReset/>}/>
            <Route path="/" element={<Page/>}/> */}

        </Routes>

        {/* {showBottomBar && <BottomNavigationBar />} */}
        <Footer {...global}/>


      </Router>


    </div>
  );
}
