import './index.scss'
export default class{
  constructor (el,device,touch) {
    this.DOM = {
      el:el,
      sliderOne:el.querySelector('.Arangesingle_top_min'),
      sliderTrack:el.querySelector('.Arangesingle_top_track'),
      sliderVal:el.querySelector('.Arangesingle_top_value'),
    }

    this.max = this.DOM.sliderOne.max
    this.minGap = 0

    this.fillColor(this.DOM.sliderOne.value)
  }

  async create () {




  }
  

  async show(){


    
  }
  async hide(){
    
  }

  fillColor(val){

    let percent1 = (val / this.max) * 100

    // this.DOM.sliderOne.value = minc

    this.DOM.sliderTrack.style.right = 100 - percent1+'%' 
    this.DOM.sliderVal.innerHTML = val
    percent1 = Math.min(90,percent1)
    percent1 = Math.max(10,percent1)
    this.DOM.sliderVal.style.left = percent1+'%'
  }


  initEvents(){
    this.DOM.sliderOne.oninput = () =>{
      this.fillColor(this.DOM.sliderOne.value  )
    }
  }

  

  onResize(){
   
  
  }

}
