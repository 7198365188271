function browserCheck(){
  //No memoria scroll
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
  }
  
  // TOUCH
  // var isTouch = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || window.innerWidth < 1181;
  var isTouch = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
  console.log(navigator.maxTouchPoints)
  if(!isTouch){
    var devicec = 'desktop'
    var devnum = 0
    document.documentElement.classList.add("smooth")
    
    if(window.innerWidth < 768){
      //Show resolution no compatible with device
    }
  
  }
  else{
    var  devicec = 'mobile'
    var devnum = 3
    if(window.innerWidth > 767){
      if(window.innerWidth > window.innerHeight){
        devicec = 'tabletL'
        devnum = 1
      }
      else{
        //Show rotate
        devicec = 'tabletS'
        devnum = 2
        
      }
    }
    
    document.documentElement.classList.add("touch")
    document.documentElement.classList.add(devicec)
  }
  


  //WebP

  return {
    'deviceclass':devicec,
    'device':devnum,
    'isTouch':isTouch,
  }
}

function revCheck (){
  const checkfix = document.createElement('div')
  checkfix.className = 'checkfix'
  checkfix.insertAdjacentHTML('afterbegin','<div class="checkfix_t"></div>')
  
  document.querySelector('body').appendChild(checkfix)
  let ratio = ((window.innerWidth*9)/window.innerHeight).toFixed(2)
  let zoom = window.innerWidth !== window.outerWidth
  document.querySelector('.checkfix_t').innerHTML = 'Width: '+window.innerWidth+'<br>Height: '+window.innerHeight+'<br>Ratio: '+ratio+'/9<br>Zoom: '+zoom

  window.addEventListener('resize',()=>{

    let zoom = window.innerWidth !== window.outerWidth
    let ratio = ((window.innerWidth*9)/window.innerHeight).toFixed(2)
    document.querySelector('.checkfix_t').innerHTML = 'Width: '+window.innerWidth+'<br>Height: '+window.innerHeight+'<br>Ratio: '+ratio+'/9<br>Zoom: '+zoom
  })
}

export default { browserCheck, revCheck }
