
import React, { useEffect, useState } from 'react' 
import { useTranslation } from 'react-i18next';

import {ReactComponent as Chevron} from '../svg/chevron.svg';
import {ReactComponent as MarkerMini} from '../svg/markermini.svg';
import {ReactComponent as CalMini} from '../svg/calendarmini.svg';
import {ReactComponent as ClockMini} from '../svg/clockmini.svg';
import { formatUSDCostToTwoDecimals } from '../utils';

import "../components/Cart/index.scss"
import "../components/Cart/producer.scss"



const ProducerCartItem = ({item}) =>{

    let name = item.producer.name
    let items = item.items
    let address = item.delivery_address
    let date = item.date
    let timeRange = item.time_range
    
    const {t} = useTranslation();
    const calculateSubtotal = () => {
        //iterate items and add
        let value = 125.46
        return formatUSDCostToTwoDecimals(value)
    }

    return(

        <button className="cart_group_el el">
            <img className="el_image" />
            <div className="el_text">
                <div className="el_text_t">{name}</div>
                <div className="el_text_price Aprice">
                    <div className="Aprice_t">{t('subtotal') + " :"}</div>
                    <div className="Aprice_m">{calculateSubtotal()}</div>
                </div>
            </div>
            <div className="el_right">
                <div className="total">{items.length}</div>
                <div className="el_right_c">
                    <Chevron />
                </div>
            </div>
            <div className="el_address">
                <h4 className="el_address_t">{t("deliverTo") + " :"}</h4>
                <div className="el_address_line">
                    <div className="i">
                        <MarkerMini />
                    </div>
                    <p className="t">{address}</p>
                </div>
                <div className="el_address_line">
                    <div className="i">
                        <CalMini />
                    </div>
                    <p className="t">{date}</p>
                </div>
                <div className="el_address_line">
                    <div className="i">
                        <ClockMini />
                    </div>
                    <p className="t">{timeRange}</p>
                </div>
            </div>
        </button>
    )
}



export default ProducerCartItem
