
import React, {useEffect, useState} from 'react' 

import "../components/Alert/index.css"

import { useTranslation } from "react-i18next"
import farmer from '../images/search/farmer.jpg'

import { dateDiffInDays } from '../utils'


const AlertItemUnseen = (props) => {

    let title = props.title
    let description = props.description
    let sent_time = props.sent_time
    const [age, setAge] = useState("now")
    useEffect(() =>{

        if(sent_time){

            let now = new Date()
            let diff = dateDiffInDays(now, sent_time)
            setAge(diff)
        }

    }, [])

    return (
        <a
            className="notif_hold_el el el-new" >
            <div className="el_image"><img src={farmer} />
                <div className="el_image_a"></div>
            </div>
            <div className="el_data">
                <div className="el_data_t">{title}</div>
                <div className="el_data_r">{age}</div>
            </div>
            <p className="el_msg">{description}</p>
        </a>
    )

}

const AlertItemSeen = (props) => {

    let title = props.title
    let description = props.description
    let sent_time = props.sent_time

    const [age, setAge] = useState("now")

    useEffect(() =>{

        if(sent_time){

            let now = new Date()
            let diff = dateDiffInDays(now, sent_time)
            setAge(diff)
        }

    }, [])
    return (

        <a className="notif_hold_el el">
            <div className="el_image"><img src={farmer} />
                <div className="el_image_a"></div>
            </div>
            <div className="el_data">
                <div className="el_data_t">{title}</div>
                <div className="el_data_r">{age}</div>
            </div>
            <p className="el_msg">{description}</p>
        </a>
    )
}



export default function Alerts({alertSelected, messageSelected}){

    let alert1 = {title:"Out For Delivery", description:"Your order #233457LGRQS is on its way!", sent_time:new Date(), is_seen:false} 
    let alert2 = {title:"Out For Delivery", description:"Your order #233457LGRQS is on its way!", sent_time:new Date(), is_seen:true} 

    let alerts = [alert1, alert2, alert2, alert2]
    let messages = [alert1, alert1, alert1, alert2]

    

    useEffect(() =>{


    }, [])

    return(

        <div className="alerts">
            <div className="alerts_bg"></div>
            <div className="alerts_group">
                <div className="alerts_group_titles">
                    
                    <button className="sel act">Notifications <span>1</span></button>
                    <button className="sel">Messages <span></span></button></div>

                <div className="alerts_group_els">
                    <div className="notif act">
                        <div className="notif_hold" data-lenis-prevent="data-lenis-prevent">
                            
                            {alerts && alerts.map((item, index) =>{
                                item.key = index
                                return (item.is_seen ? <AlertItemSeen {...item} /> : <AlertItemUnseen {...item}/>)
                            })}
                        </div>
                        <div className="notif_button"><a className="Abtn Abtn-inv Abtn-s">
                                <div className="Abtn_t">View All</div>
                            </a>
                        </div>
                    </div>
                    <div className="notif">
                        <div className="notif_hold" data-lenis-prevent="data-lenis-prevent">
                            {messages && messages.map((item, index) =>{
                                    item.key = index
                                    return (item.is_seen ? <AlertItemSeen {...item} /> : <AlertItemUnseen {...item}/>)
                                })}
                        </div>
                        <div className="notif_button"><a className="Abtn Abtn-inv Abtn-s">
                                <div className="Abtn_t">View All</div>
                            </a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
