
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRealmApp } from "../../RealmApp";
import { useNavore } from "../../hooks/useNavore";
import fruit from "../../images/search/fruit.jpg"


import {ReactComponent as DeliveryMini} from '../../svg/deliverymini.svg';
import {ReactComponent as BagMini} from '../../svg/bagmini.svg';
import farmer from "../../images/search/farmer.jpg";

import { calculateNextAvailableDelivery, getAvailableCategoriesString, makeKey } from '../../utils';


const DeliverSearchItem = (props) =>{
    return(<a className="deliversearch_blocks_el el">
        <div className="el_image">
            <img src={props.image} />
        </div>
        <h5 className="el_t">{props.name}</h5>
    </a>)
    
}



export default function Deliversearch(props) {


    let { t } = useTranslation()
    let name = props.name


    let catString = getAvailableCategoriesString(props)
    let nextDelivery = calculateNextAvailableDelivery(props)
    let products = props.all_inventory
    let minimum_delivery = props.minimum_delivery_price
    

    useEffect(() => {

    }, [])

    return (
    <article className="deliversearch">
        <a className="deliversearch_main">
            <div className="deliversearch_main_top">
                <img src={farmer} />
                <h3 className="deliversearch_main_t">{name}</h3>
            </div>
            <div className="deliversearch_main_bottom bottom">
                <h4 className="bottom_t">{catString}</h4>
                <div className="bottom_els">
                    <div className="bottom_els_line">
                        <div className="i">
                            <DeliveryMini/>
                        </div>
                        <p className="t">{nextDelivery}</p>
                    </div>
                    <div className="bottom_els_line">
                        <div className="i">
                            <BagMini/>
                        </div>
                        <p className="t">${minimum_delivery} order min</p>
                    </div>
                </div>
            </div>
        </a>
        <div className="deliversearch_blocks">
            <div className="deliversearch_blocks_hold">
                {
                    products && products.map((item, index) =>{
                        let key = makeKey()
                        item.key =  key + index
                        return(<DeliverSearchItem {...item}/>)
                    })
                }
                    
            </div>
        </div>
    </article>
    )
}
