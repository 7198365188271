
import React, { useEffect, useState } from 'react' 
import {ReactComponent as MarkerMini} from '../svg/markermini.svg';
import {ReactComponent as CalMini} from '../svg/calendarmini.svg';
import {ReactComponent as ClockMini} from '../svg/clockmini.svg';
import {ReactComponent as Trash} from '../svg/trash.svg';
import {ReactComponent as Info} from '../svg/info.svg';
import { useTranslation } from 'react-i18next';
import { formatUSDCostToTwoDecimals } from '../utils';

import "../components/Cart/index.scss"
import "../components/Cart/producer.scss"



const UpsellItem = ({item}) =>{

    let subtotal = item.subtotal
    let item_name = item.name
    let item_price = item.price
    let weight_unit = item.weight_unit
    const {t} = useTranslation();

    const calculateTotal = () =>{
        let amount = 6.50
        return formatUSDCostToTwoDecimals(amount)
    }
    return(
        <div className="upsell">
            <div className="upsell_top"><img className="upsell_top_image" />
                <h5 className="upsell_top_t">{item_name}</h5>
                <h6 className="upsell_top_s">Unit <span>· 1 ct · ${item_price} /lb</span></h6>
                <h6 className="upsell_top_s upsell_top_s-xs">Unit <span>· 1 {weight_unit}</span><span>${item_price} /lb</span></h6>
                <h6 className="upsell_top_p">{calculateTotal()}</h6>
            </div>
            <div className="upsell_bottom">
                <div className="Aqty">
                    <button className="Aqty_minus"></button>
                    <div className="Aqty_total">1</div><button className="Aqty_plus"></button>
                </div>
                <button className="upsell_delete Adelete">
                    <Trash />
                </button>
            </div>
        </div>
    )
}



const  CartItem = ({item}) =>{

    let subtotal = item.subtotal
    let item_name = item.name
    let item_price = item.price
    let weight_unit = item.weight_unit
    const {t} = useTranslation();


    const calculateTotal = () =>{
        //TODO figure out item cost structure
        let amount = 6.50
        return amount
    }

    const formatTotalAsCurrency = () =>{
        //TODO figure out item cost structure
        let amount = calculateTotal()
        return formatUSDCostToTwoDecimals(amount)
    }
    
    return(

    
        <div className="cart_producer_hold" data-lenis-prevent="data-lenis-prevent">
            <div className="Aprodcontrol">
                <div className="Aprodcontrol_top"><img className="Aprodcontrol_top_image" />
                    <div className="Aprodcontrol_top_left">
                        <div className="Aprodcontrol_top_t">{item_name}</div>
                        <div className="Aprodcontrol_top_s">Case <span>10 lb</span></div>
                    </div>
                    <div className="Aprodcontrol_top_right">
                        <div className="Aprodcontrol_top_price">{formatTotalAsCurrency()}</div>
                        <div className="Aprodcontrol_top_sprice">${item_price} /lb</div>
                    </div>
                </div>
                <div className="Aprodcontrol_bottom">
                    <div className="Aqty"><button className="Aqty_minus"></button>
                        <div className="Aqty_total">1</div><button className="Aqty_plus"></button>
                    </div><button className="Aprodcontrol_bottom_delete Adelete">
                        <Trash />
                    </button>
                </div>
            </div>
        </div>
    )
}




export default function CartItems({cart, suggestions}){

    const {t} = useTranslation();
    const [producerName, setProducerName] = useState("")
    let minumum = cart.producer.minimum_order

    useEffect(() => {

        if(cart.producer.name){
            setProducerName(cart.producer.name)
        }

    }, [cart])

    const getBarWidth = () =>{


        let width = ((calculateTotal() / 100)  / minumum) * 100
    
        let value = width.toFixed(0) + "%"

        return value
    }
    

    //Calculate and return Total in US Cents
    const calculateTotal = () =>{

        let total = 0

        for(const item of cart.items){
            //TODO figure out cost structure
            let value = 6.50
            total = total + (value * 100)
        }

        return total
    }

    const formatTotalAsCurrency = () =>{
        //TODO figure out item cost structure
        let amount = calculateTotal()
        return formatUSDCostToTwoDecimals(amount)
    }

    return(

        <div className="cart_producer">

            {/* START Add More Products */}
            <div className="cart_producer_add add">

                <div className="add_titlexs">
                    <h3 className="add_titlexs_t">Add more products</h3>
                </div>
                <div className="add_title">
                    <h4 className="add_title_t">Handpicked {producerName} products we think you'll love</h4>
                </div>


                <div className="add_hold" data-lenis-prevent="data-lenis-prevent">

                    {suggestions && suggestions.map((item, index) => {
                        return(<UpsellItem key={index} item={item} />)
                    })}

                    
                </div>


                <div className="add_end">
                    <button className="Abtn Abtn-l Abtn-inv">
                        <div className="Abtn_t">Add for {formatTotalAsCurrency()}</div>
                    </button>
                    <button className="Alink outAdd">No thanks, take me to check out</button>
                </div>

                {/* END Add More Products */}

            </div>

            <div className="cart_producer_main">
                <button className="cart_producer_close Aclose"><span></span><span></span></button>
                <div className="cart_producer_total">{cart.items.length} items</div>
                <div className="cart_producer_info info">
                    <h3 className="info_t">Your {cart.name} cart</h3>
                    <div className="info_address">
                        <h4 className="info_address_t">Deliver to :</h4>
                        <div className="info_address_main">
                            <div className="el">
                                <div className="el_i">
                                    <MarkerMini />
                                </div>
                                <p className="el_t">{cart.address}</p>
                            </div>
                        </div>
                        <div className="info_address_sche">
                            <div className="el">
                                <div className="el_i">
                                    <CalMini />
                                </div>
                                <p className="el_t">{cart.date}</p>
                            </div>
                            <div className="el">
                                <div className="el_i">
                                    <ClockMini />
                                </div>
                                <p className="el_t">{cart.time_range}</p>
                            </div>
                        </div>
                    </div>
                </div>


                {cart.items && cart.items.map((item, index) => {
                    return(<CartItem item={item} key={index}/>)
                })}



                <div className="cart_producer_end cart_producer_end-less">
                    <div className="total">
                        <div className="total_t">Total:</div>
                        <div className="total_m">{formatUSDCostToTwoDecimals(calculateTotal() / 100)}</div>
                    </div>
                    <div className="progress">
                        <div className="progress_b">
                            <div className="progress_b_per" style={{ width: getBarWidth() }}></div>
                        </div>
                        <div className="Atip act">
                            <div className="Atip_hold">
                                <div className="Atip_i">
                                    <Info />
                                </div>
                                <div className="Atip_t">
                                    <h5>You're almost there!</h5>
                                    <p>To complete your order with this producer, please add more products to reach the ${minumum} minimum.</p>
                                </div>
                            </div>
                        </div>
                    </div><button className="Abtn Abtn-l Abtn-inv toAdd">
                        <div className="Abtn_t">Add more products</div>
                    </button>
                </div>

            </div>
        </div>

 
    )
}
