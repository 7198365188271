
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";





// SERVER CONFIG
const firebaseConfig = {
  apiKey: "AIzaSyDTeD1D4yCh5Dcc6GFTpmOrpCXpa8FCTmo",
  authDomain: "navore-b76ec.firebaseapp.com",
  projectId: "navore-b76ec",
  storageBucket: "navore-b76ec.appspot.com",
  messagingSenderId: "975255207345",
  appId: "1:975255207345:web:d7dcddc6718b1c55ba1190",
  measurementId: "G-8P6W2MQPNW"
};


const app = initializeApp(firebaseConfig);
// enableIndexedDbPersistence(db)

// const functions = getFunctions(app);
// const auth = getAuth(app);
const storage = getStorage(app);
const anaylytics = getAnalytics(app);
function isSafari() {
  return (navigator.vendor.match(/apple/i) || "").length > 0
}


var messaging;
const initMessaging = () =>{
    try{
        messaging = getMessaging(app);
    }catch (err){
        console.log(err)
    }
}



// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6Ld679YfAAAAAAHMaLjGm42lHXjOuIvLnWk9_s51'),
//
//   isTokenAutoRefreshEnabled: true
// });



//Parameters: file: the local file reference
async function uploadImageWith(file, handler){

  var metadata = {
    cacheControl: 'public,max-age=31526000',
  }

  let date = Date.now()
  const storageRef = ref(storage, 'images/' + date, metadata);

  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, file).
  console.log('Uploaded a blob or file!');

  let url = await getDownloadURL(storageRef)
  console.log('File available at', url);

  return url

}




//Parameters: file: the local file reference
function startResumableUpload(file, handler){

  let date = Date.now()
  var metadata = {
    cacheControl: 'public,max-age=31526000',
  }
  const storageRef = ref(storage, 'images/' + date);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      handler({complete:false, progress:progress})
      switch (snapshot.state) {
        case 'paused':
          handler({complete:false, progress:progress, message:"paused"})

          break;
        case 'running':
          handler({complete:false, progress:progress, message:"running"})

          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          handler({complete:false,  message:"Unauthorized", error:error})

          break;
        case 'storage/canceled':
          // User canceled the upload
          handler({complete:false,  message:"Cancelled", error:error})
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          handler({complete:false,  message:"Cancelled", error:error})

          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>{
        console.log('File available at', downloadURL);
        handler({complete:true, url:downloadURL})
      });
    }
  );
}





export const onMessageListener = () =>

      new Promise((resolve) => {
        onMessage(messaging, (payload) => {
          resolve(payload);
        });
      });









const getFCMToken = async() =>{

    const vapid_key = 'BHpYW895pDzidsL-B9pXradTNChhPExRjEue8WDDVAb12aw7zm4X-whi66r5GwgvvbPGoslqhHMAXbe9PLOlsWw'

    try{
        let currentToken = await getToken(messaging, { vapidKey: vapid_key })
        if (currentToken) {
            console.log('current token for client: ', currentToken);

            return currentToken

        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            return "request"
        }

    }catch(err) {
            console.log('An error occurred while retrieving token. ', err);
            return "error"
    }
}



export {
  getFCMToken,
  uploadImageWith,
  startResumableUpload


}
