import './index.scss'

export default class{
  constructor (el,device,touch) {
    this.DOM = {
      el:el,
      sliderOne:el.querySelector('.Arange_top_min'),
      sliderTwo:el.querySelector('.Arange_top_max'),
      sliderTrack:el.querySelector('.Arange_top_track'),
      nptOne:el.querySelector('.npt_min input'),
      nptTwo:el.querySelector('.npt_max input'),
      reset:el.querySelector('.Arange_bottom_reset'),
    }

    this.max = this.DOM.sliderOne.max
    this.minGap = 0
  }

  async create () {




  }
  

  async show(){


    
  }
  async hide(){
    
  }

  fillColor(min,max){
    let minc = Math.max(min,0)
    let maxc = Math.min(max,this.max)

    let percent1 = (minc / this.max) * 100
    let percent2 = (maxc/ this.max) * 100

    this.DOM.sliderOne.value = minc
    this.DOM.sliderTwo.value = maxc
    this.DOM.nptOne.value = minc
    this.DOM.nptTwo.value = maxc

    this.DOM.sliderTrack.style.left = percent1+'%'
    this.DOM.sliderTrack.style.right = 100 - percent2+'%' 
  }


  initEvents(){
    this.DOM.sliderOne.oninput = () =>{
      if(parseInt(this.DOM.sliderOne.value) > parseInt(this.DOM.sliderTwo.value)){
        this.DOM.sliderOne.value = this.DOM.sliderTwo.value
      }
      this.fillColor(this.DOM.sliderOne.value ,this.DOM.sliderTwo.value )
    }

    this.DOM.sliderTwo.oninput = () =>{
      if(parseInt(this.DOM.sliderOne.value) > parseInt(this.DOM.sliderTwo.value)){
        this.DOM.sliderTwo.value = this.DOM.sliderOne.value
      }
      this.fillColor(this.DOM.sliderOne.value ,this.DOM.sliderTwo.value )
    }


    this.DOM.nptOne.oninput = () =>{
      this.DOM.nptOne.value =  Math.min(this.DOM.nptOne.value,this.max)
      this.DOM.nptOne.value =  Math.max(this.DOM.nptOne.value,0)
      this.DOM.nptTwo.value =  Math.min(this.DOM.nptTwo.value,this.max)
      this.DOM.nptTwo.value =  Math.max(this.DOM.nptTwo.value,this.DOM.nptOne.value)
      if(parseInt(this.DOM.nptTwo.value) - parseInt(this.DOM.nptOne.value) <= this.minGap){
        this.DOM.sliderOne.value = parseInt(this.DOM.nptTwo.value) - this.minGap
      }
      this.fillColor(this.DOM.nptOne.value,this.DOM.nptTwo.value)
    }

    this.DOM.nptTwo.oninput = () =>{
      this.DOM.nptOne.value =  Math.min(this.DOM.nptOne.value,this.max)
      this.DOM.nptOne.value =  Math.max(this.DOM.nptOne.value,0)
      this.DOM.nptTwo.value =  Math.min(this.DOM.nptTwo.value,this.max)
      this.DOM.nptTwo.value =  Math.max(this.DOM.nptTwo.value,this.DOM.nptOne.value)
      if(parseInt(this.DOM.nptTwo.value) - parseInt(this.DOM.nptOne.value) <= this.minGap){
        this.DOM.sliderTwo.value = parseInt(this.DOM.sliderOne.value) + this.minGap
      }
      this.fillColor(this.DOM.nptOne.value,this.DOM.nptTwo.value)
    }


    this.DOM.reset.onclick = () =>{
      this.DOM.nptOne.value = 0
      this.DOM.nptTwo.value = this.max
      this.fillColor(this.DOM.nptOne.value,this.DOM.nptTwo.value)
    }

  }

  

  onResize(){
   
  
  }

}
