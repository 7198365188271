
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { useRealmApp } from "../../RealmApp";
import { useNavore } from "../../hooks/useNavore";

import {ReactComponent as DeliveryMini} from '../../svg/deliverymini.svg';
import {ReactComponent as BagMini} from '../../svg/bagmini.svg';
import { getAvailableCategoriesString, calculateNextAvailableDelivery } from '../../utils';

const ShopSearchItem = (props) =>{


    let catString = getAvailableCategoriesString(props)
    let nextDelivery = calculateNextAvailableDelivery(props)

    let products = props.all_inventory
    let minimum_pickup = props.minimum_pickup_price

    useEffect(() =>{

       

    }, [props])




return (
    <article className="shopsearch">
        <a className="shopsearch_main">
            <div className="shopsearch_main_top"><img src={props.cover_image} />
                <h3 className="shopsearch_main_t">{props.name}</h3>
            </div>
            <div className="shopsearch_main_bottom bottom">
                <h4 className="bottom_t">{catString}</h4>
                <div className="bottom_els">
                    <div className="bottom_els_line">
                        <div className="i">
                            <DeliveryMini/>
                        </div>
                        <p className="t">{nextDelivery}</p>
                    </div>
                    <div className="bottom_els_line">
                        <div className="i">
                            <BagMini/>
                        </div>
                        <p className="t">{minimum_pickup} order min <span>3 min</span></p>
                    </div>
                </div><button className="Abtn Abtn-inv Abtn-s">
                    <div className="Abtn_t">View story</div>
                </button>
            </div>
        </a>
    </article>
    )

}

const Shopsearch = (props) => {

    let items = props.items

    let {t} = useTranslation()

    useEffect(() => {

    }, [items])

    return(

        <>
            {items && items.map((item, index) =>{
                item.key = index
                return(<ShopSearchItem {...item}/>)

            })}
        </>
    )
}
export default  Shopsearch