
import React from 'react' 
import {ReactComponent as InfoFill} from '../svg/infofill.svg';
import {ReactComponent as CheckCircle} from '../svg/checkcircle.svg';
import {ReactComponent as FalseCircle} from '../svg/falsecircle.svg';



export default function CartDelete({name}){

    return(
 
            <div className="modal modalDelete">
                <div className="modal_bg"></div>
                <div className="modal_content"><button className="modal_close Aclose"><span></span><span></span></button>
                        <div className="modal_content_deleteprod" data-lenis-prevent="data-lenis-prevent">
                            <div className="modal_i">
                                <InfoFill/>
                            </div>
                            <h2 className="modal_t">Are you sure you want to delete {name} from your cart?</h2><button
                                className="Abtn Abtn-l Abtn-inv">
                                <div className="Abtn_i">
                                    <CheckCircle/>
                                </div>
                                <div className="Abtn_t">Yes, delete</div>
                            </button><button className="Abtn Abtn-l">
                                <div className="Abtn_i">
                                    <FalseCircle/>
                                </div>
                                <div className="Abtn_t">No</div>
                            </button>
                        </div>
                </div>
            </div>
        

    )
}
