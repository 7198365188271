import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../RealmApp";
import realmjsondata from "../realm.json";
import { BSON } from "realm-web";
import { ADD_TO_MEDIA_GALLERY,DELETE_ONE_MEDIA_GALLERY,UPDATE_MEDIA_GALLERY,GET_MEDIA_GALLERY,GET_ONE_PRODUCER_PROFILE,UPDATE_PUBLIC_PROFILE, UPDATE_PRIVATE_PROFILE,
  GET_ALL_ORDERS_FOR_ID, GET_ALL_PRODUCES, GET_PRODUCE_BY_TYPE, GET_ONE_PRODUCER_INVENTORY, QUERY_PRODUCER_PROFILES_PICKUP, QUERY_PRODUCER_PROFILES_DELIVERY,
  QUERY_PRODUCER_INVENTORY, GET_TOP_PRODUCER_PROFILES, GET_PRODUCER_PLANS, GET_PRODUCER_INVENTORIES, GET_PRIVATE_PROFILE, GET_PUBLIC_PROFILE, GET_PRODUCER_STORIES } from "./fragments";



const DEV_DATABASE = "navore-dev"
const PROD_DATABASE = "navore"

//Currently In DEVELOPEMENT
const DATABASE = PROD_DATABASE







export function useNavore() {

  // Get a graphql client and set up a list of todos in state
  const realmApp = useRealmApp();
  const graphql = realmApp.apolloClient

  const [privateProfile, setPrivateProfile] = React.useState(null);
  const [publicProfile, setPublicProfile] = React.useState(null);
  const [products, setProducts] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  
  const [cachedProducers, setCachedProducers] = React.useState(null)
  const [cachedProducts, setCachedProducts] = React.useState(null)
  const [cartInventory, setCartInventory] = React.useState(null);


  const publicProfileCollection = useCollection({
    cluster: realmjsondata.dataSourceName,
    db: DATABASE,
    collection: "PublicProfile",
  });

  const privateProfileCollection = useCollection({
    cluster: realmjsondata.dataSourceName,
    db: DATABASE,
    collection: "PrivateProfile",
  });

  const producerProfileCollection = useCollection({
    cluster: realmjsondata.dataSourceName,
    db: DATABASE,
    collection: "ProducerProfile",
  });


  const producerInventoryCollection = useCollection({
    cluster: realmjsondata.dataSourceName,
    db: DATABASE,
    collection: "ProducerInventory",
  });





  // Fetch all todos on load and whenever our graphql client changes (i.e. either the current user OR App ID changes)
  React.useEffect(() => {

    if(realmApp.currentUser !== null && realmApp.currentUser !== undefined){
      fetchProfiles()
    }

  }, [realmApp.currentUser, graphql]);

  // Fetch all todos on load and whenever our apolloClient client changes (i.e. either the current user OR App ID changes)
  const fetchProfiles = async() =>{

    await getPrivateProfile(realmApp.currentUser.id)
    
    setLoading(false)
  }








/************************************************************
* CACHE MODIFYING QUERY's
*********************************************************************/

//USE for on create productInventory
const createMedia = (cache, { data }) => {
  cache.modify({ 
    fields: {
      producerLocations(exisitingMediaGallery = []) {
        const newMedia = data.insertOneMediaGallery;
        cache.writeQuery({
          query: GET_MEDIA_GALLERY,
          data: { newMedia, ...exisitingMediaGallery }
        });
      }
    }
  })
};




//USE for on delete productInventory
const removeMedia = (cache, { data }) => {
  cache.modify({ 
    fields: {
      producerLocations(exisitingMediaGallery = []) {
        const deletingMedia = data.deleteOneMediaGallery;

        var filtered = exisitingMediaGallery.filter(el => !el.__ref.includes(deletingMedia._id.toString()) ); 

        cache.writeQuery({
          query: GET_MEDIA_GALLERY,
          data: { filtered }
        });
      }
    }
  })
};
/********************************
*  END -- CACHE MODIFYING QUERY's
*********************************/



/************************************************************
CACHE MODIFYING MUTATION's
*********************************************************************/

const [addMediaGallery, { addMediaCalled, addMediaError }] = useMutation(ADD_TO_MEDIA_GALLERY,{update:createMedia});
const [deleteMediaGallery, { deleteMediaCalled, deleteMediaError }] = useMutation(DELETE_ONE_MEDIA_GALLERY,{update:removeMedia});
/**************************************
*  END -- CACHE MODIFYING MUTATIONS's
* ***********************************/


  async function getPrivateProfile(id){

    let response = await graphql.query({ query:GET_PRIVATE_PROFILE, variables:{_partition:id} })
    let pri = response.data.privateProfile

    setPrivateProfile(pri);
  }

  async function getPublicProfile(id){

    let response = await graphql.query({ query:GET_PUBLIC_PROFILE, variables:{_partition:id} })
    let pub = response.data.publicProfile
    setPublicProfile(pub)

  }

  async function getProducerInventory(_producer_id){
    let response = await graphql.query({ query:GET_PRODUCER_INVENTORIES, variables:{_producer_id:_producer_id} })
    let pub = response.data.producerInventories

    return pub
  }

  async function getTopProducerPublicProfiles(){

    let response = await graphql.query({ query:GET_TOP_PRODUCER_PROFILES })
    let pub = response.data

    if(pub == null)return []
    return pub.producerProfiles
  }

  async function getNearbyProducerNews(coordinates, distance){


    let type = 'Point'

    console.log('COORDS: ', coordinates, distance)
    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);
    const producer_news = mdb.db(realmjsondata.navoreDatabase).collection("ProducerNews")
    try {
      let result = await producer_news.find(
        {
          location:
            { 
              $nearSphere :
               {
                 $geometry: { type: type,  coordinates: coordinates },
                 $maxDistance: Number(distance)
               }
            }
        }
     )
      return result
    } catch (err) {
        console.error(err);
        return undefined
    }


  }



  async function queryNearbyProducers(coordinates, radiusMiles){
    let geotype = 'Point'

    let distanceMeters = radiusMiles * 1600

    console.log("CACHED producer: ", cachedProducers, cachedProducts)
    if(cachedProducers != null && cachedProducts != null){
      return {producers: cachedProducers, products: cachedProducts}

    }else if (cachedProducers != null && cachedProducts == null){

      let ids = getProducerIdsFromList(cachedProducers)

      let products = await getProductsForProducersIn(ids)
      return {producers:cachedProducers, products:products}
    }

    let loc_query = {
      location:
        { 
          $nearSphere :
           {
             $geometry: { type: geotype,  coordinates: coordinates },
             $maxDistance: Number(distanceMeters)
           }
        }
    }

    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);
    const producer_profiles = mdb.db(realmjsondata.navoreDatabase).collection("ProducerProfile")

    let producers_response = await producer_profiles.find(loc_query)

    if(producers_response == null) {return {producers:[], products:[]}}
    console.log(producers_response)

    let ids = getProducerIdsFromList(producers_response)

    let products = await getProductsForProducersIn(ids)

    setCachedProducers(producers_response)
    return {producers:producers_response, products:products}

  }

  function getProducerIdsFromList(producers){


    let ids = []
    for(const producer of producers){
      if(!ids.includes(producer._partition)){
        ids.push(producer._partition)
      }
    }

    return ids
  }

  async function getProductsForProducersIn(ids){
    const mdb = realmApp.currentUser.mongoClient(realmjsondata.dataSourceName);

    const producer_inventory = mdb.db(realmjsondata.navoreDatabase).collection("ProducerInventory")

    let mapping = {}
    for(const id of ids){
      mapping[id] = []
    }

    let product_query = {
      _producer_id:
      { 
        $in : ids
      },
      status: { 
        $in : ['active', 'wholesale']
      },
    }

    let products_response = await producer_inventory.find(product_query)

    for(const prod of products_response){
      mapping[prod._producer_id].push(prod)
    }

    setCachedProducts(mapping)

    return mapping
  }


  async function queryAllProducersDelivery(type){

    let response
    if(type === 'delivery'){
      response = await graphql.query({ query:QUERY_PRODUCER_PROFILES_DELIVERY, variables:{delivery:true} })

    }else if(type === 'pickup'){
      response = await graphql.query({ query:QUERY_PRODUCER_PROFILES_PICKUP, variables:{pickup:true} })
    }

    let pub = response.data.producerProfiles

    if(pub == null)return []
    return pub

  }

  async function getTopProducerStories(){

    let response = await graphql.query({ query:GET_PRODUCER_STORIES })
    let pub = response.data.producerNews
    return pub
  }


  async function getProducerPublicProfile(_partition){

    let response = await graphql.query({ query:GET_ONE_PRODUCER_PROFILE, variables:{_partition:_partition} })
    let pub = response.data.producerProfile
    return pub
  }


  async function getAllProduce(){

    let response = await graphql.query({ query:GET_ALL_PRODUCES})
    let pub = response.data
    if(!pub ){return []}

    return pub

  }

  async function getProduceForType(type){

    let response = await graphql.query({ query:GET_PRODUCE_BY_TYPE, variables:{type:type} })
    let pub = response.data

    return pub

  }



  async function getAllOrders(id){
    
    let response = await graphql.query({ query:GET_ALL_ORDERS_FOR_ID, variables:{_partition:id} })
    let pub = response.data
    return pub
  }

  async function getAllOpenOrders(id){

    const query = gql`
      query FetchAllOpenOrders($order_completed: Boolean!, $_producer_id:String!) {
        orders ( query: {  order_completed: $order_completed , _producer_id: $_producer_id } ){
          _id
          _partition
          name
          cover_image
          images
          recipes
          latitude
          longitude
        }
      }
    `;
    let response = await graphql.query({ query:query, variables:{order_completed:false, _producer_id:id} })
    let pub = response.data
    return pub
  }



  // useWatch(privateProfileCollection, {
  //   onUpdate: (change) => {
  //     console.log("CHANGE to PRIVATE PROFILE", change)
  //     if(loading){
  //       setPrivateProfile(privateProfile)
  //     }else{
  //       setPrivateProfile(change.fullDocument)
  //     }
  //   }
  // });

  async function setInventoryWatcher(){
      for await (const change of producerInventoryCollection.watch ({
        filter: {
          operationType: "update",
          "fullDocument.status": "public",
        },
      })) {
        // The change event will always represent a newly inserted perennial
        const { documentKey, fullDocument } = change;
        console.log(`new document: ${documentKey}`, fullDocument);
        break; // Exit async iterator
      }

  }


  // useWatch(producerInventoryCollection, {
  //   onUpdate: (change) => {
  //     console.log("CHANGE to Producer Inventory", change)
  //     if(loading){
  //       setCart(privateProfile)
  //     }else{
  //       setCart(change.fullDocument)
  //     }
  //   }
  // });



  async function getProduct(id){

    let objectid = BSON.ObjectID(id)
      
    let response = await graphql.query({ query:GET_ONE_PRODUCER_INVENTORY, variables:{id:objectid} })
    let product = response.data.producerInventory

    return product
  }


  const updateBothProfiles = async (profileData) => {
    if (!realmApp.currentUser) return;
    let id = realmApp.currentUser.id
    
    try {
        let pri_result = await graphql.mutate({
          mutation: UPDATE_PRIVATE_PROFILE,
          variables: { profile:profileData, id:id },
        });
        setPrivateProfile(pri_result)

        let pub_result = await graphql.mutate({
          mutation: UPDATE_PUBLIC_PROFILE,
          variables: { profile:profileData, id:id },
        });
  
        setPublicProfile(pub_result)

        return pri_result
      } catch (err) {
          if (err.message.match(/^Duplicate key error/)) {
            console.warn(
              `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
            );
          }
        console.error(err);
      }
  };




  //Set any private profile field

  const updatePrivateProfile = async (profileData) => {

    if (!realmApp.currentUser) return;
    let id = realmApp.currentUser.id
    
    try {
        let result = await graphql.mutate({
          mutation: UPDATE_PRIVATE_PROFILE,
          variables: { profile:profileData, id:id },
        });

        return result
      } catch (err) {
          if (err.message.match(/^Duplicate key error/)) {
            console.warn(
              `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
            );
          }
        console.error(err);
        return undefined
      }
  };

  
  const updatePublicProfile = async (profileData) => {

    if (!realmApp.currentUser) return;
    let id = profileData._partition

    try {
      await graphql.mutate({
          mutation: UPDATE_PUBLIC_PROFILE,
          variables: { profile: profileData, id:id },
        });
      } catch (err) {
        console.error(err);
      }
  };

  /******************************************
  * ***************************************
  *  START -- MEDIA GALLERY
  * ****************************************
  * **************************************/


    // async function getMediaGallery(producer_id){

    //   let response = await graphql.query({ query:GET_MEDIA_GALLERY, variables:{_producer_id:producer_id} })
    //   let pub = response.data.mediaGallery
    //   setMediaGallery(pub)
    //   return pub
    // }
  

    // async function addToMediaGallery(media){
  
    //   if (!realmApp.currentUser) return;
  
    //   try {
  
    //     let result = await addMediaGallery({variables:{ data: media }})
  
  
    //     let newM = result.data.insertOneMediaGallery
  
    //     if(newM && newM.status === 'public'){
    //       updateActiveMediaProducerProfile([newM], true)
    //     }
  
    //     return result
    //     } catch (err) {
    //       console.error(err);
    //       return undefined
    //     }
    // }
  
  
    // const updateMediaGallery = async (data, id) =>{
  
    //   if (!realmApp.currentUser) return;
  
    //   try {
    //     let result = await graphql.mutate({
    //         mutation: UPDATE_MEDIA_GALLERY,
    //         variables: { data: data, _id:id },
    //       });
  
  
    //       let update = result.data.updateOneMediaGallery
    //       updateGallery(update)
  
    //       if(update.status === 'public'){
    //         updateActiveMediaGalleryProducerProfile([update], true)
    //       }else{
    //         updateActiveMediaGalleryProducerProfile([update], false)
    //       }
    //       return result
    //     } catch (err) {
    //       console.error(err);
    //       return undefined
    //     }
    // }
  
    
    // async function deleteFromMediaGallery(media){
  
    //   let fullMediaGallery = {}
  
    //   for(const med of mediaGallery){
    //     if(media.includes(med._id.toString())){
    //       fullMediaGallery[med._id.toString()] = med
    //     }
    //   }
  
    //   try {
  
    //     let deleted = []
    //     let fullDeletedMedia = []
    //     for(const pid of media){
  
    //       let result = await deleteMediaGallery({variables:{id:pid} })
    //       deleted.push(pid)
    //       fullDeletedMedia.push(fullMediaGallery[pid])
    //     }
  
  
    //     deleteMedia(deleted)
    //     updateActiveMediaGalleryProducerProfile(fullDeletedMedia, false)
  
    //     return {success:true, message:"Media(s) deleted"}
  
    //   } catch (err) {
    //     if (err.message.match(/^Duplicate key error/)) {
    //       console.warn(
    //         `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
    //       );
    //     }
    //     console.log("Error: ", err.message)
    //     return {success:false, message:err.message}
    
    //   }
    // }
  
  
      //If a product is active we add it to the producer profiles array of all_inventory
    //This allows the geo querying of producers and inventory to work with one call
    //This also sets the available_categories in which is also queried
    // async function updateActiveMediaGalleryProducerProfile(newMedia, isActive){
  
    //   //Adding/Removing from producers profile property 'all_inventory' based on parameters provided
    //   if(producerProfile == null) {return}
    //   let medias = JSON.parse(JSON.stringify(mediaGallery))
  
    //   let updatedMedia = []
    //   let newMediaMap = {}
  
    //   for(const med of newMedia){
    //     let id = med._id.toString()
    //     newMediaMap[id] = med
    //   }
  
  
    //   for(const med of medias){
  
    //     let newMed = newMediaMap[loc._id.toString()]
  
    //     if(newLoc != null){
    //       updatedMedia.push(newMed)
    //     }else{
    //       updatedMedia.push(med)
    //     }
    //   }
  
    //   //first we must convert objectid array to string array for comparison
    //   let newLinks = []
  
  
    //   for(const med of updatedMedia){
    //     if(med.status === 'public'){
    //       newLinks.push(BSON.ObjectID(med._id.toString()))
    //     }
    //   }
  
  
    //   let updates = {active_locations:{link:newLinks}}
  
    //   await updateMedia(updates, producerProfile._partition)
      
    // }
  
  
    // //Update a producer product
    // function updateMedia(change){
  
    //   if(!change){return}
        
    //   var tmp = JSON.parse(JSON.stringify(mediaGallery))
  
    //   for(const i in tmp){
    //       let p = tmp[i]
  
    //       if(p._id.toString() === change._id.toString()){
    
    //         tmp[i] = change
    //         break
    //       }
    //   }
  
    //   setMediaGallery(tmp)
    // }
  
    // //Delete one or more multiple producer products
    // function deleteMedia(changes){
  
    //   if(changes.length < 1){return}
  
    //   var tmp = JSON.parse(JSON.stringify(mediaGallery))
    //   var filtered = tmp.filter(function(el) { return !changes.includes(el._id.toString())}); 
  
    //   setMediaGallery(filtered)
    // }
  
  /*****************************************
  *  END -- MEDIA GALLERY
  * **************************************/



  return {
    loading,
    updatePrivateProfile,
    updateBothProfiles,
    getProduceForType,
    queryNearbyProducers,
    privateProfile,
    getProduct,
    setPrivateProfile,
    getAllOrders,
    getAllOpenOrders,
    getProducerPublicProfile,
    queryAllProducersDelivery,
    getTopProducerPublicProfiles,
    getNearbyProducerNews,
    getProducerInventory,
    getAllProduce,
    products

  };
}
