
import React, {useEffect, useState} from 'react' 


import "../components/Cart/index.scss"
import "../components/Cart/producer.scss"
import ProducerCartsList from './ProducerCartItem';
import CartItems from "./CartItems"
import ProducerCartItem from "./ProducerCartItem"
import { useTranslation } from 'react-i18next';


export default function Cart({producer_carts}){

    const {t} = useTranslation();




    useEffect(() => {
        console.log("DATA: ", producer_carts)
    }, [])



    return(
        <div className="cart">
            <div className="cart_bg"></div>
            <div className="cart_group">
                <h3 className="cart_group_title">{t('myCarts')}</h3>
                <div className="cart_group_hold" data-lenis-prevent="data-lenis-prevent">
                    {producer_carts && producer_carts.map((item, index) =>{
                        return(<ProducerCartItem key={index} item={item} />)
                    })}
                </div>
            </div>

            {producer_carts && producer_carts.map((item, index) =>{
               return( <CartItems key={index} cart={item}/>)
            }) } 
        </div>
    )
}
