
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { useRealmApp } from "./RealmApp";
import { useNavore } from "./hooks/useNavore";

import {ReactComponent as SearchIcon} from './svg/search.svg';
import {ReactComponent as Delivery} from './svg/delivery.svg';
import {ReactComponent as Pickup} from './svg/pickup.svg';
import {ReactComponent as FilterAlert} from './svg/filteralert.svg';
import {ReactComponent as Arrow} from './svg/arrow.svg';
import {ReactComponent as Grid} from './svg/grid.svg';
import {ReactComponent as List} from './svg/list.svg';
import {ReactComponent as MarkerMap} from './svg/markermap.svg';
import {ReactComponent as Shop} from './svg/shop.svg';


import {ReactComponent as Cat1} from './svg/cats/1.svg';
import {ReactComponent as Cat2} from './svg/cats/2.svg';
import {ReactComponent as Cat3} from './svg/cats/3.svg';
import {ReactComponent as Cat4} from './svg/cats/4.svg';
import {ReactComponent as Cat5} from './svg/cats/5.svg';
import {ReactComponent as Cat6} from './svg/cats/6.svg';
import {ReactComponent as Cat7} from './svg/cats/7.svg';
import {ReactComponent as Cat8} from './svg/cats/8.svg';


import Deliversearch from './basic/search/Deliversearch';
import Pickupsearch from './basic/search/Pickupsearch';
import Shopsearch from  './basic/search/Shopsearch';
import Lfilters from './basic/search/Lfilters';
import Range from './atoms/range';
import Rangesingle from './atoms/rangesingle'
import fruit from "./images/search/fruit.jpg"
import farmer from "./images/search/farmer.jpg"
import { makeKey } from './utils';

import productsdata from './products.json'


const CategoryButtons = () =>{

    let catetory_icons = [{icon:<Cat1/>, name:"Fruit"}, {icon:<Cat2/>, name:"Vegetables"}, {icon:<Cat3/>, name:"Meat and Fish"}, {icon:<Cat4/>, name:"Dairy and Eggs"}, {icon:<Cat5/>, name:"Pantry"}, {icon:<Cat6/>, name:"Mushrooms"}, {icon:<Cat7/>, name:"Herbs"}, {icon:<Cat8/>, name:"Bakery"}]

    return (
        <>
            {catetory_icons.map((item, index) =>{
                return(<button key={index} className="catBtn" aria-label="select category">
                    {item.icon}
                    <div className='catBtn_t'>{item.name}</div>
                </button>)

            })}
        </>
    )
}

const organizeProductFilters = () => {
  let all = {}

  for(const item of productsdata){

    if(all[item.Category]){
      all[item.Category].push(item.Type)
    }else{
      let newArr = []
      if(item.Type !== ""){
        newArr.push(item.Type)
      }
      all[item.Category] = newArr
    }
  }
  return all

}



export default function Search(){



    let {user} = useRealmApp()
    let { getTopProducerPublicProfiles } = useNavore()

    let {t} = useTranslation()
    const [DOM, setDOM] = useState(null)
    const [rangeL, setRangeL] = useState(null)
    const [rangesingleL, setRangesingleL] = useState(null)
    const [timeoutbub, setTimeoutbub] = useState(null)
    const [producerProfiles, setProducerProfiles] = useState(null)

    const allProductFilters = organizeProductFilters()


    let test_products = [{name:"Cauliflower", image:fruit},{name:"Yellow Peach", image:fruit},{name:"Brocoli", image:fruit},{name:"Pears", image:fruit},{name:"Brie cheese", image:fruit},{name:"Honey Crisp Apple", image:fruit}]
    let test_producers = [{minumum_collection_time_hours:24, all_inventory:test_products, name:"Yasukochi Family Farms", minimum_delivery_price:50, minimum_pickup_price:25, cover_image:farmer}, {minumum_collection_time_hours:24, all_inventory:test_products, name:"Yasukochi Family Farms", minimum_delivery_price:50, minimum_pickup_price:25, cover_image:farmer}]

    useEffect(() => {


        onResize()
        if(DOM === null){
            create()
        }else{
          initEvents()

        }


    }, [DOM])


    useEffect(() => {

      if(!producerProfiles){
        getNearbyProducers()
      }


  }, [producerProfiles])


    const getNearbyProducers = async() =>{
      let profiles = await getTopProducerPublicProfiles()
      setProducerProfiles(profiles)

    }
    async function create () {

        let sDOM = {el:document.querySelector('.searchpage')}
    
        sDOM.topbar =  sDOM.el.querySelector('.stck_top')
        sDOM.main =  sDOM.el.querySelector('.stck_main')
        sDOM.map =  sDOM.el.querySelector('.stck_map')
          
          //Controls
        sDOM.cDelivery = sDOM.el.querySelector('.toDel')
        sDOM.cPickup = sDOM.el.querySelector('.toPick')
        sDOM.cShop = sDOM.el.querySelector('.toShop')
        sDOM.cShopback = sDOM.el.querySelector('.toBack')
        sDOM.cGrid = sDOM.el.querySelector('.gridBtn')
        sDOM.cList = sDOM.el.querySelector('.listBtn')
        sDOM.cFilters = sDOM.el.querySelector('.filterBtn')

        //Controls Portrait
        sDOM.cDeliveryP = sDOM.el.querySelector('.toDelP')
        sDOM.cPickupP = sDOM.el.querySelector('.toPickP')
        sDOM.cShopP = sDOM.el.querySelector('.toShopP')
        sDOM.cFiltersP = sDOM.el.querySelector('.filterBtnP')
      
          
          //Holders for elements
        sDOM.deliverHold = sDOM.el.querySelector('.deliverHold')
        sDOM.pickHold = sDOM.el.querySelector('.pickHold')
        sDOM.shopHold = sDOM.el.querySelector('.shopHold')
      
      
          //filters
        sDOM.filtersL = sDOM.el.querySelector('.filtersL') 
        sDOM.searchL = sDOM.el.querySelector('.searchbarL') 
        sDOM.searchP = sDOM.el.querySelector('.searchbarP') 
        sDOM.filtersClose = sDOM.el.querySelector('.filtersCloseP_i')
        sDOM.filtersTitle = sDOM.el.querySelector('.filtersCloseP_t') 
          
      
          //Map
        sDOM.cFullMap = sDOM.el.querySelector('.fullMap')
        sDOM.cHalfMap = sDOM.el.querySelector('.halfMap')
        sDOM.cZoomin = sDOM.el.querySelector('.zoomIn')
        sDOM.cZoomout = sDOM.el.querySelector('.zoomOut')
        sDOM.cSearchMap = sDOM.el.querySelector('.SearchMap')
      
      
        sDOM.cMarkerShopNum = sDOM.el.querySelector('.markerShopNum')
        sDOM.cMarkerPickNum = sDOM.el.querySelector('.markerPickNum')
      
        let tRangeL = new Range(sDOM.el.querySelector('.Arange'))
        let tRangesingleL = new Rangesingle(sDOM.el.querySelector('.Arangesingle'))

      
          //BUBBLES
      
      
          // DOM.bubDel = DOM.el.querySelector('.bubDel')
          // DOM.bubDelP = DOM.el.querySelector('.bubDelP')
          // DOM.bPickup = DOM.el.querySelector('.bubPick')
          // DOM.bPickupP = DOM.el.querySelector('.bubPickP')
          // DOM.bubShop = DOM.el.querySelector('.bubShop')
    


        //START
        sDOM.deliverHold.classList.add('act')
        sDOM.pickHold.style.display = 'none'
        sDOM.shopHold.style.display = 'none'
       

        setRangeL(tRangeL)
        setRangesingleL(tRangesingleL)
        setDOM(sDOM)
        
        

    }
    

      
    function showBub(els){
        if(timeoutbub) {
          window.clearTimeout(timeoutbub)
        }
        for(let a of DOM.el.querySelectorAll('.Abubble')){
          a.classList.remove('act')
        }
        for(let a of els){
  
          let el = DOM.el.querySelector(a)
          let close = el.querySelector('.Abubble_close')
          el.classList.add('act')
          if(close){
            close.onclick = ()=>{
              el.classList.remove('act')
            }
          }
        }
    
        let tobub = setTimeout(()=>{
            for(let a of els){
              let el = DOM.el.querySelector(a)
              el.classList.remove('act')
            }
      
          },4600)
        setTimeoutbub(tobub)
      }

    
    function  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
    }
    
    function initEvents(){
    //CONTROLS ELEMENTS
      // MUST HAVE A TRANSITION OPACITY 0

        DOM.cDelivery.onclick = async() => {
    
            showBub(['.bubDel','.bubDelP'])
            DOM.pickHold.classList.remove('act')
            DOM.shopHold.classList.remove('act')
            await timeout(600)
            DOM.pickHold.style.display = 'none'
            DOM.shopHold.style.display = 'none'
            DOM.deliverHold.style.display = ''
            DOM.deliverHold.classList.add('act')
      
            // emit('actdeliver')
      
            DOM.cPickup.classList.remove('act')
            DOM.cDelivery.classList.add('act')
            DOM.el.classList.remove('actmap')
            DOM.el.classList.remove('actmap-full')
            DOM.el.classList.remove('actmap-P')
            DOM.el.classList.remove('actshop-P')
      
      
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
          
      
      
          DOM.cPickup.onclick = async() => {
      
            showBub(['.bubPick','.bubPickP','.bubShop'])
            DOM.deliverHold.classList.remove('act')
            DOM.shopHold.classList.remove('act')
            await timeout(600)
            DOM.deliverHold.style.display = 'none'
            DOM.shopHold.style.display = 'none'
            DOM.pickHold.style.display = ''
            DOM.pickHold.classList.add('act')
      
            // emit('actpickup')
      
            DOM.cDelivery.classList.remove('act')
            DOM.cPickup.classList.add('act')
            DOM.cPickupP.classList.remove('act')
            DOM.cDeliveryP.classList.add('act')
      
            DOM.el.classList.add('actmap')
            if(DOM.el.classList.contains('actfilter')){
              DOM.cGrid.classList.add('act')
              DOM.cList.classList.remove('act')
              DOM.el.classList.add('actgrid')
            }
      
      
      
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
      
          }
      
          DOM.cShopback.onclick = async() =>{
            showBub(['.bubPick','.bubPickP'])
            DOM.deliverHold.classList.remove('act')
            DOM.shopHold.classList.remove('act')
            DOM.el.classList.remove('actmap-P')
            DOM.el.classList.remove('actshop-P')
            DOM.cShopP.classList.remove('act')
            await timeout(600)
            DOM.deliverHold.style.display = 'none'
            DOM.shopHold.style.display = 'none'
            DOM.pickHold.style.display = ''
            DOM.pickHold.classList.add('act')
      
            // emit('actpickup')
      
            DOM.cDelivery.classList.remove('act')
            DOM.cPickup.classList.add('act')
            DOM.cDeliveryP.classList.remove('act')
            DOM.cPickupP.classList.add('act')
      
            
          
          
            DOM.el.classList.remove('actshop')
      
      
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
      
      
          DOM.cShop.onclick = async() =>{
            showBub(['.bubShop'])
      
      
            
            DOM.deliverHold.classList.remove('act')
            DOM.pickHold.classList.remove('act')
            await timeout(600)
            DOM.deliverHold.style.display = 'none'
            DOM.pickHold.style.display = 'none'
            DOM.shopHold.style.display = ''
            DOM.shopHold.classList.add('act')
      
            // emit('actpickup')
      
            // DOM.cDelivery.classList.remove('act')
            // DOM.cPickup.classList.remove('act')
            DOM.cShop.classList.remove('act')
      
            DOM.el.classList.remove('actfilter')
            DOM.el.classList.remove('actmap-full')
            DOM.cGrid.classList.add('act')
            DOM.cList.classList.remove('act')
            DOM.el.classList.add('actgrid')
            DOM.el.classList.add('actshop')
            DOM.cFilters.classList.remove('act')
            DOM.cFiltersP.classList.remove('act')
            
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
      
      
          //MOBILE CONTROLS
  
          DOM.cDeliveryP.onclick = async() =>{
      
            showBub(['.bubDel','.bubDelP'])
      
            DOM.pickHold.classList.remove('act')
            DOM.shopHold.classList.remove('act')
            await timeout(600)
            DOM.pickHold.style.display = 'none'
            DOM.shopHold.style.display = 'none'
      
            DOM.deliverHold.style.display = ''
            DOM.deliverHold.classList.add('act')
            DOM.el.classList.remove('actmap-P')
            DOM.el.classList.remove('actshop-P')
      
            // emit('actdeliver')
      
            DOM.cPickup.classList.remove('act')
            DOM.cDelivery.classList.add('act')
            DOM.cPickupP.classList.remove('act')
            DOM.cDeliveryP.classList.add('act')
      
            DOM.el.classList.remove('actmap')
            DOM.el.classList.remove('actmap-full')
      
      
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
          DOM.cPickupP.onclick = async() =>{
      
            showBub(['.bubPick','.bubPickP','.bubShop'])
            DOM.deliverHold.classList.remove('act')
            DOM.shopHold.classList.remove('act')
            await timeout(600)
            DOM.deliverHold.style.display = 'none'
            DOM.shopHold.style.display = 'none'
            DOM.pickHold.style.display = ''
            DOM.pickHold.classList.add('act')
      
            // emit('actpickup')
      
            DOM.cDelivery.classList.remove('act')
            DOM.cPickup.classList.add('act')
            DOM.cDeliveryP.classList.remove('act')
            DOM.cPickupP.classList.add('act')
      
            DOM.el.classList.add('actmap')
            if(DOM.el.classList.contains('actfilter')){
              DOM.cGrid.classList.add('act')
              DOM.cList.classList.remove('act')
              DOM.el.classList.add('actgrid')
            }
      
      
      
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
      
          }
      
      
      
          //CONTROLS STYLE VIEW
          DOM.cList.onclick = async() =>{
            if(DOM.el.classList.contains('actfilter') && DOM.el.classList.contains('actmap')){
              return false
            }
            DOM.cGrid.classList.remove('act')
            DOM.cList.classList.add('act')
            DOM.el.classList.remove('actgrid')
          }
      
          DOM.cGrid.onclick = async() =>{
            
            DOM.cList.classList.remove('act')
            DOM.cGrid.classList.add('act')
            DOM.el.classList.add('actgrid')
          }
      
          //CONTROLS FILTERS
      
      
          DOM.cFilters.onclick = async() =>{
            if(DOM.el.classList.contains('actfilter')){
      
              DOM.cFilters.classList.remove('act')
              DOM.cFiltersP.classList.remove('act')
              DOM.el.classList.remove('actfilter')
            }
            else{
              DOM.cFilters.classList.add('act')
              DOM.el.classList.add('actfilter')
              if(DOM.el.classList.contains('actmap')){
                DOM.el.classList.add('actgrid')
                DOM.cGrid.classList.add('act')
                DOM.cList.classList.remove('act')
      
              }
            }
            
      
          }
      
          DOM.cFiltersP.onclick = async() =>{
            if(DOM.el.classList.contains('actfilter')){
      
              DOM.cFilters.classList.remove('act')
              DOM.cFiltersP.classList.remove('act')
              DOM.el.classList.remove('actfilter')
            }
            else{
              DOM.cFilters.classList.add('act')
              DOM.el.classList.add('actfilter')
              if(DOM.el.classList.contains('actmap')){
                DOM.el.classList.add('actgrid')
                DOM.cGrid.classList.add('act')
                DOM.cList.classList.remove('act')
      
              }
            }
            
      
          }
          DOM.filtersClose.onclick = () =>{
            if(DOM.filtersL.querySelector('.filtersL_tab.act')){
              DOM.filtersL.querySelector('.filtersL_tab.act').classList.remove('act')
            }
            else if(DOM.filtersL.querySelector('.filtersL_days').classList.contains('act')){
      
              DOM.filtersL.querySelector('.filtersL_days').classList.remove('act')
            }
            else{
              DOM.el.classList.remove('actfilter')
      
            }
            DOM.filtersTitle.innerHTML = DOM.filtersTitle.parentNode.dataset.text
      
          }
      
          //FILTERS
          for(let a of DOM.filtersL.querySelectorAll('.filtersL_tab_click')){
            a.onclick = ()=>{
              if(a.parentNode.classList.contains('act')){
                a.parentNode.classList.remove('act')
              }
              else{
                DOM.filtersTitle.innerHTML = a.querySelector('.click_t').innerHTML
                if(DOM.filtersL.querySelector('.filtersL_tab.act')){
                  DOM.filtersL.querySelector('.filtersL_tab.act').classList.remove('act')
                  
                }
                a.parentNode.classList.add('act')
              }
            }
          }
      
          //DAYS
      
          DOM.filtersL.querySelector('.filtersL_days_click').onclick = ()=>{
            DOM.filtersL.querySelector('.filtersL_days').classList.add('act')
            DOM.filtersTitle.innerHTML = 'Pick up days'
          }
      
      
          //MAP CONTROLS
      
          DOM.cFullMap.onclick = () =>{
            DOM.el.classList.add('actmap-full')
          }
      
          DOM.cHalfMap.onclick = () =>{
            DOM.el.classList.remove('actmap-full')
          }
      
          DOM.cMarkerPickNum.onclick = () => {
      
            showBub([])
            DOM.el.classList.add('actmap-P')
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
      
      
          DOM.cMarkerShopNum.onclick = async() =>{
            showBub([])
      
            DOM.el.classList.add('actmap-P')
            DOM.el.classList.add('actshop-P')
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
            DOM.cShopP.classList.add('act')
           
      
            
              
          }
      
          DOM.cShopP.onclick = async() =>{
      
            showBub([])
      
            DOM.el.classList.add('actmap-P')
            DOM.el.classList.add('actshop-P')
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
            DOM.cShopP.classList.add('act')
           
      
            
              
          }
          
          // INIT RANGE SLIDER
          rangeL.initEvents()
          rangesingleL.initEvents()
        // DOM.bubDel.classList.add('act')
        // DOM.bubDelP.classList.add('act')
        showBub(['.bubDel','.bubDelP'], DOM)
    
    }
    
      
    
    function onResize(){

        if(!DOM){
            return
        }
        let plus = (DOM.topbar.clientHeight + document.querySelector('.nav .nav_top').clientHeight)
        DOM.filtersL.parentNode.style.top = plus+'px'
        DOM.filtersL.parentNode.style.height = window.innerHeight-plus+'px'
        DOM.map.querySelector('.stck_map_hold').style.top = plus+'px'
        DOM.map.querySelector('.stck_map_hold').style.height = window.innerHeight-plus+'px'
        
        let topmain = DOM.topbar.clientHeight + parseInt(window.getComputedStyle(DOM.main).getPropertyValue('margin-top'))
        DOM.filtersL.parentNode.parentNode.style.top = topmain+'px'
        DOM.filtersL.parentNode.parentNode.style.height = 'calc(100% - '+topmain+'px)'
        DOM.map.style.top = topmain+'px'
        DOM.map.style.height = 'calc(100% - '+topmain+'px)'
    }


    return(
        <main className="searchpage">
            <section className="searchpage_main">
                <div className="checksP">
                    <div className="Abubble bubDelP">
                        <div className="Abubble_arrow"></div>
                        <div className="Abubble_close"></div>
                        <div className="Abubble_hold">
                            <p>Producers bring your order to you</p>
                        </div>
                    </div>
                    <div className="Abubble bubPickP">
                        <div className="Abubble_arrow"></div>
                        <div className="Abubble_close"></div>
                        <div className="Abubble_hold">
                            <h2 className="Abubble_hold_t">Pick up</h2>
                            <p>You choose when and where to pick up your order</p>
                        </div>
                    </div>
                    <button className="toDelP act" aria-label="Show deliveries">

                        <Delivery/>
                    </button>
                    <button className="toPickP" aria-label="Show pickups">
                        <Pickup/>
                    </button>
                    <div className="checksP_ball"></div>
                </div>
                <div className="searchbarL c-vw">
                    <div className="Abubble bubDel">
                        <div className="Abubble_arrow"></div>
                        <div className="Abubble_close"></div>
                        <div className="Abubble_hold">
                            <p>Producers bring your order to you</p>
                        </div>
                    </div>
                    <div className="Abubble bubPick">
                        <div className="Abubble_arrow"></div>
                        <div className="Abubble_close"></div>
                        <div className="Abubble_hold">
                            <h2 className="Abubble_hold_t">Pick up</h2>
                            <p>You choose when and where to pick up your order</p>
                        </div>
                    </div>
                    <div className="searchbarL_hold">
                        <form className="searchbarL_npt">
                            <div className="searchbarL_npt_i">
                                <SearchIcon/>
                            
                            </div><input className="searchbarL_npt_field"
                                placeholder="Start typing a product or producer…" type="text" name="search" id="search" />
                        </form>
                        <button className="filterBtnP act hasAlert" >

                            <FilterAlert/>
                        </button>
                    <div className="searchbarL_checks"><button className="toDel act" aria-label="Show deliveries">
                        <Delivery/>
                    </button>
                    <button className="toPick" aria-label="Show pickups">
                        
                        <Pickup/>
                        </button>
                        <div className="searchbarL_checks_ball"></div>
                    </div>
                </div>
            </div>
            <div className="catsmain">
                {<CategoryButtons/>}
            </div>

            <div className="stck">
                <div className="stck_top c-vw">
                    <div className="stck_top_shop c-vw"><button className="toBack">
                        <div className="toBack_i">
                            <Arrow/>
                        </div>
                        <div className="toBack_t">Back to search</div>
                    </button>
                        <h3>Shop in person</h3>
                    </div>
                    <div className="stck_top_controls">
                        <h2 className="stck_top_tdeliver">Top producers and products in your area</h2>
                        <h2 className="stck_top_tpickup">showing 23 results</h2>
                        <div className="controls">
                            <button className="filterBtn controls_el act hasAlert">
                            <div className="controls_el_i">
                                <FilterAlert/>
                            </div>
                            <div className="controls_el_t">Show Filters</div>
                        </button>
                        <button className="gridBtn controls_el">
                                <div className="controls_el_i">
                                    
                                    <Grid/>
                                </div>
                                <div className="controls_el_t">Grid</div>
                            </button><button className="listBtn controls_el act">
                                <div className="controls_el_i">
                                    <List/>
                                </div>
                                <div className="controls_el_t">List</div>
                            </button>
                            <div className="controls_delete"><button className="deleteBtn controls_delete_el">Delete
                                filters </button></div>
                        </div>
                    </div>
                </div>
                <div className="stck_left">
                    <div className="stck_left_hold" data-lenis-prevent>
                        <Lfilters/>
                    </div>
                </div>
                <div className="stck_main">
                    <div className="deliverHold">
                        <div className="deliverHold_title">
                            <h2 className="deliverHold_title_t">Search producers in your area</h2>
                        </div>

                        {
                          test_producers && test_producers.map((item, index) =>{
                            let key = makeKey() + index
                            item.key= key
                            return(<Deliversearch {...item}/>)

                          })
                        }

                        <div className="deliverHold_title">
                            <h2 className="deliverHold_title_t">25 producers match most of your search</h2>
                        </div>

                        {
                          test_producers && test_producers.map((item, index) =>{
                            let key = makeKey() + index
                            item.key= key
                            return(<Deliversearch {...item}/>)

                          })
                        }
                    </div>

                    <div className="pickHold">
                      {
                          test_producers && test_producers.map((item, index) =>{
                            let key = makeKey() + index
                            item.key= key
                            return(<Pickupsearch {...item}/>)

                          })
                        }
                    </div>

                    <div className="shopHold">
                      {
                          test_producers && test_producers.map((item, index) =>{
                            let key = makeKey() + index
                            item.key= key
                            return(<Shopsearch {...item}/>)

                          })
                        }
                    </div>
                </div>
                <div className="stck_map">
                    <div className="stck_map_slider">
                        <div className="shopHoldP">
                        {
                          test_producers && test_producers.map((item, index) =>{
                            let key = makeKey() + index
                            item.key= key
                            return(<Shopsearch {...item}/>)

                          })
                        }
                        </div>
                        <div className="pickHoldP">
                        {
                          test_producers && test_producers.map((item, index) =>{
                            let key = makeKey() + index
                            item.key= key
                            return(<Pickupsearch {...item}/>)

                          })
                        }
                        </div>
                    </div>
                    <div className="stck_map_hold">
                        <div className="markerYou" style={{left:'50%',bottom:'50%'}}>
                            <MarkerMap/>
                        </div>
                        <button className="markerPick" style={{right:'45%',top:'25%'}}>
                            <MarkerMap/>
                        </button>
                        
                        <button className="markerShop" style={{right:'20%',top:'20%'}}>

                            <MarkerMap/>
                        </button>
                        <button className="markerShop" style={{left:'20%',bottom:'20%'}}><MarkerMap/>
                        </button>
                        <button className="markerShopNum" style={{left:'40%',bottom:'60%'}}>
                            <div className="markerShopNum_t">3</div><MarkerMap/>
                        </button>
                        <button className="markerPickNum" style={{left:'10%',top:'30%'}}>
                            <div className="markerPickNum_t">4</div>
                            <MarkerMap/>
                        </button>
                        <button className="markerPickNum act" style={{left:'10%', bottom:"10%"}}>
                            <div className="markerPickNum_t">5</div>
                            
                           <MarkerMap/>
                        </button><button className="fullMap">
                                <Arrow/>
                        </button><button className="halfMap">
                            <div className="halfMap_i">
                                <List/>
                            </div>
                            <div className="halfMap_t">Show list</div>
                        </button><button className="searchHere">Search Here</button>
                        <div className="zoom"><button className="zoomIn">+</button><button
                            className="zoomOut">-</button></div>
                        <div className="Abubble bubShop">
                            <div className="Abubble_arrow"></div>
                            <div className="Abubble_close"></div>
                            <div className="Abubble_hold">
                                <h2 className="Abubble_hold_t">Shop in person</h2>
                                <p>Want your goods now? Check out local farm stands near you and head out to shop in
                                    person.</p>
                            </div>
                        </div>
                        <button className="toShop">
                            <Shop/>
                        </button>
                        <button className="toShopP">
                            <Shop/>
                        </button>
                        <div id="map"></div>
                    </div>
                </div>
            </div>
        </section>
    </main >
)
}
