
import React, {useEffect, useState} from 'react' 
import Cart from './Cart'
import CartDelete from './CartDelete'
import Alerts from './Alerts'

import { useRealmApp } from "../RealmApp";
import { useNavore } from '../hooks/useNavore';

import {ReactComponent as Logo} from "../svg/logo.svg"
import {ReactComponent as LogoXs} from "../svg/logoxs.svg"
import {ReactComponent as Search} from '../svg/search.svg';
import {ReactComponent as MarkerMini} from '../svg/markermini.svg';
import {ReactComponent as BellAlert} from '../svg/bellalert.svg';
import {ReactComponent as BagAlert} from '../svg/bagalert.svg';
import {ReactComponent as Home} from '../svg/home.svg';
import {ReactComponent as Chevron} from '../svg/chevron.svg';
import {ReactComponent as User} from '../svg/user.svg';

import "../components/Nav/index.scss"
import "../components/Address/index.scss"
import "../components/Address/producer.scss"

import "../scss/theme/main.scss"


export default function Nav(){

    const [cartIsOpen, setCartIsOpen] = useState(0)
    const [alertIsOpen, setAlertsIsOpen] = useState(0)
    const [isOpen, setIsOpen] = useState(0)
    const [producerSel, setProducerSel] = useState(-1)
    const [alertpos, setAlertPos] = useState(0)

    const { currentUser } = useRealmApp();
    const {loading, privateProfile, publicProfile} = useNavore()


    const [cart, setCart] = useState(0)


    const [deleteItem, setDeleteItem] = useState(0)
    const [setupComplete, setSetupComplete] = useState(0)

  
    const [DOM, setDOM] = useState({
      el: null,
      burger: null,
      cartbtn: null,
      alertsbtn: null,
      cartbtnxs: null,
      cartbg: null,
      cartels: null,
      cartproducers: null,
    });


    /** START TESTING DATA */
    let item = {name:"Broccoli Crowns", unit:"count", weight_per_unit:3.25, price:3.25, count:2}
    let item2 = {name:"Long Name for testing purposes", unit:"count", weight_per_unit:2.15, price:2.15, count:2}

    let producer_cart1 = {items:[item, item2], producer:{name:"J&A Harvest Ranch", minimum_order:50, _id:"a09dsjf", image_url:"imageurl"}, delivery_address:"3030 Front St, San Diego, CA 92102", date:"Friday, February 27th", time_range:"12 – 4:00 PM"}
    let producer_cart2 = {items:[item, item2], producer:{name:"J&A Harvest Ranch", minimum_order:50, _id:"a09dsjf", image_url:"imageurl"}, delivery_address:"3030 Front St, San Diego, CA 92102", date:"Friday, February 27th", time_range:"12 – 4:00 PM"}

    let test_data = [producer_cart1, producer_cart2]

    let upsell1 = {name:"Broccoli Crowns", unit:"count", wieght_per_unit:3.25, price:3.25, count:2}
    let upsell2 = {name:"Broccoli Crowns", unit:"count", wieght_per_unit:3.25, price:3.25, count:2}

    let all_upsells = [upsell1,upsell2]
    /** END TESTING DATA */   


    useEffect(() => {
      const fetchDOMElements = async () => {
        const newDOM = {
          el: document.querySelector('.nav'),
          burger: document.querySelector('.nav_burger'),
          cartbtn: document.querySelector('.nav_cartbtn'),
          alertsbtn: document.querySelector('.nav_alertsbtn'),
          cartbtnxs: document.querySelector('.nav_cartbtnxs'),
          cartbg: document.querySelector('.cart_bg'),
          cartels: document.querySelectorAll('.cart_group_el'),
          cartproducers: document.querySelectorAll('.cart_producer'),
        };
        setDOM(newDOM);
      };
  
      fetchDOMElements();

    }, [cartIsOpen, alertIsOpen, alertpos, producerSel]);



    useEffect(() => {

      const initEvents = () => {

        const closeCartFn = (psel)  => {

      
          document.documentElement.classList.remove('cart-act-step1')
          document.documentElement.classList.remove('cart-act')
          setCartIsOpen(0)
  
          if(psel !== -1){

            DOM.cartproducers[psel].classList.remove('act')
    
            if(DOM.cartproducers[psel].querySelector('.cart_producer_add.act-add')){
              DOM.cartproducers[psel].querySelector('.cart_producer_add.act-add').classList.remove('.act-add')
            }
          }

          setProducerSel(-1)
        }
        
        if(document.querySelector('.modalDelete')){
          document.querySelector('.modalDelete .Aclose').onclick = () =>{
            document.querySelector('.modalDelete').classList.remove('act')
          }
          document.querySelector('.modalDelete .modal_bg').onclick = () =>{
            document.querySelector('.modalDelete').classList.remove('act')
          }
    
          for(let del of document.querySelectorAll('.cart .Adelete')){
          
            del.onclick = ()=>{
              document.querySelector('.modalDelete').classList.add('act')
    
            }
          }
        }

        if(DOM.burger){

          DOM.burger.onclick = () =>{
            console.log('Burger Clicked')
              if(isOpen === 1){
                document.documentElement.classList.remove('menu-act')
                setIsOpen(0)
              }
              else{
                document.documentElement.classList.add('menu-act')
                setIsOpen(1)
              }
            }
        }

        if(DOM.alertsbtn){

        
          DOM.alertsbtn.onclick = () =>{

            if(alertIsOpen === 1){
              setAlertsIsOpen(0)
              document.documentElement.classList.remove('alerts-act')
            }
            else{
              if(cartIsOpen === 1){
                closeCartFn(producerSel)
              }
    
              document.documentElement.classList.add('alerts-act')
              setAlertsIsOpen(1)
            }
          }
    
    
          DOM.alertssels = document.querySelectorAll('.alerts .sel')
          DOM.alertsels = document.querySelectorAll('.alerts .notif')
    
          setAlertPos(0)
    
          if(DOM.alertssels){

            for(let [i,sel] of DOM.alertssels.entries()){

              sel.onclick = () => {

                console.log("ALERTS SELS: ", i, sel)

                //only two tabs so we can take the remainder of i + 1 modulo 2
                let removepos = (i + 1) % 2


                DOM.alertssels[removepos].classList.remove('act')
                DOM.alertsels[removepos].classList.remove('act')
    
                setAlertPos(i)
    
                DOM.alertssels[i].classList.add('act')
                DOM.alertsels[i].classList.add('act')
    
              }
            }
          }
        }
    
        if(DOM.cartbtn){

          DOM.cartbtn.onclick = () =>{
            
            if(cartIsOpen === 1){
              closeCartFn(producerSel)
            }
            else{
              setCartIsOpen(1)

              document.documentElement.classList.add('cart-act')
              // emit('openmenu')
    
              if(alertIsOpen === 1){
                setAlertsIsOpen(0)
                document.documentElement.classList.remove('alerts-act')
              }
            }
          }
        }

    
        if(DOM.cartbtnxs){


          DOM.cartbtnxs.onclick = () =>{


            if(cartIsOpen === 1){
              closeCartFn(producerSel)
            }
            else{
              document.documentElement.classList.add('cart-act')
              setCartIsOpen(1)
            }
          }
        }
    
        if(DOM.cartbg){
          console.log("CART BTN BG", cartIsOpen)

          DOM.cartbg.onclick = () =>{

            if(cartIsOpen === 1){
              closeCartFn(producerSel)
            }
          }
        }
    
        if(DOM.cartels){
  
          for(let [i,el] of DOM.cartels.entries()){

            el.onclick = () =>{

              document.documentElement.classList.add('cart-act-step1')
              let newProdSel = i
              setProducerSel(i)
              DOM.cartproducers[newProdSel].classList.add('act')

              DOM.cartproducers[newProdSel].querySelector('.Aclose').onclick = () => {
                closeCartFn(newProdSel)
              }
    
              if(DOM.cartproducers[newProdSel].querySelector('.toAdd')){

              
                let add = DOM.cartproducers[newProdSel].querySelector('.cart_producer_add')
                DOM.cartproducers[newProdSel].querySelector('.toAdd').onclick = ()=>{
                  add.classList.add('add-act')

                  add.querySelector('.outAdd').onclick = ()=>{
                    add.classList.remove('add-act')
                  }
                }
              }
            }
          }
        }
  
        // Add more event handling logic for other elements as needed
      };
  
      if(DOM){
        initEvents();

      }
    }, [DOM]);


    useEffect(() =>{
      console.log("CURRENT USER: ", currentUser)

    }, [loading, currentUser])






    const addressSelected = (value) => {
      console.log(value)

    }

    return(

      <nav className="nav nav-lgd">

        <Cart producer_carts={test_data}/>
        <CartDelete/> 
        <Alerts />
        
        <div className="c-vw nav_top">
            <a className="nav_logo" aria-label="Návore">
            <Logo/>
            <LogoXs/>
           </a>
            <div className="nav_address">
                <button className="nav_address_select select" aria-label="Choose Address">
                    <div className="select_i">
                        <MarkerMini/>
                        </div>
                    <div className="select_t">{privateProfile ? (privateProfile.address + ", " + privateProfile.city) : "Address"}</div>
                    <div className="select_c">
                        <Chevron/>
                        </div>
                </button>
            </div>

            <div className="nav_ops">
                <div className="nav_fixed" aria-label="Menu"><a className="nav_fixed_el el">
                        <div className="el_i">
                            <Home />
                        </div>
                    </a><a className="nav_fixed_el el">
                        <div className="el_i">
                            <Search />
                            </div>
                    </a><a className="nav_fixed_el nav_fixed_el-alert nav_fixed_el-xs hasAlert el nav_cartbtnxs">
                        <div className="el_i">
                            <BagAlert/>
                        </div>
                    </a><a className="nav_fixed_el nav_fixed_el-alert el hasAlert nav_alertsbtn">
                        <div className="el_i">
                            <BellAlert/>
                        </div>
                    </a><a className="nav_fixed_el el">
                        <div className="el_i">
                            <User/>
                        </div>
                    </a></div>
                    
                    <button className="nav_cartbtn hasAlert" aria-label="Check Bag">
                        <div className="nav_cartbtn_t">{"(" + (cart.length ? cart.length : 0) + ")"}</div>
                        <div className="nav_cartbtn_i">
                            <BagAlert/>
                        </div>
                      <div className="nav_cartbtn_c">
                          <Chevron/>
                      </div>
                </button>
            </div>
        </div>
      </nav>

    )
}
