import { gql } from '@apollo/client';


const CORE_PRODUCER_PLAN = gql`
  fragment CoreProducerPlan on ProducerPlan {
    _id
    _partition
    name
    plan_type
    latitude
    longitude
    streetAddress
    address
    city
    state
    zip
    order_cutoff_duration_days
    order_cutoff_time
    cancellation_cutoff_duration_days
    cancellation_cutoff_time
    future_order_maximum_cutoff_days
    recurring_schedules { start_date repeat_value repeat_period repeat_days }
    available_days
    minimum_order
    delivery_fee
    pickup_fee
    minimum_order_for_free_delivery
    status
    window_start_time
    window_end_time
    wholesale_groups
  }
`;

const CORE_PRODUCER_INVENTORY = gql`
  fragment CoreProducerInventory on ProducerInventory {
      _id
      nickname
      description
      internal_tag
      product_variety
      product_variety_other
      product_type
      product_category
      weight_unit
      tags
      is_available
      track_inventory
      inventory_by
      charge_by
      remaining_inventory
      status
      packs { name price unit weight_per_unit adjustments { group_name group_id adjustment_type value }  }
      attributes { name status description }
      notify_thresholds { type amount }
      images
      cover_image
  }
`;


const CORE_MEDIA_GALLERY = gql`
  fragment CoreMediaGallery on MediaGallery {
    _id
    _producer_id
    customer_submitted
    title
    description
    url
    type
    status
  }
`;

const CORE_PRODUCER_PROFILE = gql`
  ${CORE_PRODUCER_INVENTORY}
  ${CORE_PRODUCER_PLAN}
  ${CORE_MEDIA_GALLERY}
  fragment CoreProducerProfile on ProducerProfile {
    _id
    _partition
    name
    email
    cover_image
    validated
    url
    rating
    total_reviews
    location { coordinates, type }
    offers_delivery
    offers_pickup
    farm_size
    farm_type
    address
    about_land
    about_produce
    producer_story
    active_plans { ...CoreProducerPlan }
    active_media { ...CoreMediaGallery }
    timezone
    shipping_cost
    available_categories
    minimum_collection_time_hours
    all_inventory {...CoreProducerInventory}
  }
`;


const CORE_PRODUCER_ACCESS = gql`
  fragment CoreProducerAccess on ProducerAccess {
      _id
      _producer_id
      wholesale_groups { id name members }
      current_wholesale_members { _id name email cover_image }
      all_customers { _id name email cover_image }
      admin {edit read}
      settings {edit read}
      sales {edit read}
      operations {edit read}
      media {edit read}
      all_associates { _partition }
      owners
  }
`;


const CORE_PUBLIC_PROFILE = gql`
  fragment CorePublicProfile on PublicProfile {
    _id
    _partition
    name
    email
    cover_image
  }
`;





const CORE_PRODUCER_STORY = gql`
  fragment CoreProducerStories on ProducerStories {
    _id
    _producer_id
    description
    media_id
    location_ids
    status
  }
`;

const CORE_PRODUCT_CATEGORIES = gql`
  fragment CoreProductCategories on ProductCategory{
    _id
    type
    category
  }
`;




const CORE_PRIVATE_PROFILE = gql`
fragment CorePrivateProfile on PrivateProfile {
    _id
    _partition
    producer_ids
    email
    name
    favorite_recipes { _id name}
    favorite_producers {_id name}
    favorite_products { _id product_category product_type product_variety }
    notifications_token
    location {coordinates, type}
    address
    cover_image
    permissions { admin {edit read} settings {edit read} sales {edit read} operations {edit read} media {edit read} }
}
`;


const CORE_RECIPE = gql`
fragment CoreRecipe on Recipe {
    _id
    _producer_id
    ingredients{ amount unit name }
    difficulty
    name
    time
    steps
    status
    images
    cover_image
}
`;






  // Use a MongoDB change stream to reactively update state when operations succeed
  export const GET_PRIVATE_PROFILE = gql`
  ${CORE_PRIVATE_PROFILE}
  query FetchPrivateProfile($_partition: String!) {
    privateProfile( query: { _partition: $_partition } ){
      ...CorePrivateProfile
    }
  }
  `;    
  export const GET_PUBLIC_PROFILE = gql`
  ${CORE_PUBLIC_PROFILE}
  query FetchPublicProfile($_partition: String!) {
    publicProfile( query: { _partition: $_partition } ){
      ...CorePublicProfile
    }
  }
  `;
  export const GET_PRODUCER_INVENTORIES = gql`
  ${CORE_PRODUCER_INVENTORY}
  query FetchProducerInventories($_producer_id: String!) {
    producerInventories( query: { _producer_id: $_producer_id }, limit: 500 ){
      ...CoreProducerInventory
    }
  }
  `;

  export const GET_PRODUCER_STORIES = gql`
  ${CORE_PRODUCER_STORY}
  query FetchProducerStories($_producer_id: String!) {
    producerStories( query: { _producer_id: $_producer_id }, limit: 500 ){
      ...CoreProducerStory
    }
  }
  `;

  export const GET_PRODUCER_PLANS = gql`
  ${CORE_PRODUCER_PLAN}
  query ProducerPlan($_producer_id: String!) {
    producerPlans( query: { _partition: $_producer_id } ){
      ...CoreProducerPlan
    }
  }
  `;
  export const GET_TOP_PRODUCER_PROFILES = gql`
  ${CORE_PRODUCER_PROFILE}
  query FetchAllProducerProfiles {
    producerProfiles {
      ...CoreProducerProfile
    }
  }
  `;
  
  export const QUERY_PRODUCER_INVENTORY = gql`
  ${CORE_PRODUCER_PROFILE}
  query FetchProducerInventory($query:ProducerProfileLocationQueryInput!) {
    producerProfiles(query: $query) {
      ...CoreProducerInventory
    }
  }
  `;
  export const QUERY_PRODUCER_PROFILES_DELIVERY = gql`
  ${CORE_PRODUCER_PROFILE}

  query FetchAllProducerProfiles($delivery:Boolean) {
    producerProfiles(query: {offers_delivery:$delivery}) {
      ...CoreProducerProfile
    }
  }
  `;



  export const QUERY_PRODUCER_PROFILES_PICKUP = gql`
  ${CORE_PRODUCER_PROFILE}
  query FetchAllProducerProfiles($pickup:Boolean) {
    producerProfiles(query: {offers_pickup:$pickup}) {
      ...CoreProducerProfile
    }
  }
  `;

  export const GET_ONE_PRODUCER_INVENTORY = gql`
    ${CORE_PRODUCER_INVENTORY}

      query FetchOneProducerInventory($id: ObjectId!) {
        producerInventory( query: { _id: $id } ){
          ...CoreProducerInventory
        }
      }
  `;
  export const GET_PRODUCE_BY_TYPE = gql`
  ${CORE_PRODUCT_CATEGORIES}
  query FetchAllProductCategories($type: String!) {
    productCategories (query: { type: $type }){
      ...CoreProductCategories
    }
  }
`;

export const GET_ALL_PRODUCES = gql`
  ${CORE_PRODUCT_CATEGORIES}
  query FetchAllProductCategories {
    productCategories (limit: 500){
      ...CoreProductCategories
    }
  }
  `;
  export const GET_ALL_ORDERS_FOR_ID = gql`
      query FetchAllOrders($_partition: String!) {
        orders( query: { _partition: $_partition } ){
          _id
          _partition
          name
          cover_image
          images
          recipes
          latitude
          longitude
        }
      }
  `;





  export const UPDATE_PRIVATE_PROFILE = gql`
  ${CORE_PRIVATE_PROFILE}
    mutation SavePrivateProfile($profile: PrivateProfileUpdateInput!, $id:String!) {
      updateOnePrivateProfile(query: { _partition: $id }, set: $profile ) {
        ...CorePrivateProfile
      }
    }
  `;
  export const  UPDATE_PUBLIC_PROFILE = gql`
  ${CORE_PUBLIC_PROFILE}

  mutation SavePublicProfile($profile: PublicProfileUpdateInput!, $id:String!) {
    updateOnePublicProfile(query: { _partition: $id }, set:  $profile ) {
      ...CorePublicProfile
    }
  }
  `;
  export const GET_ONE_PRODUCER_PROFILE = gql`
  ${CORE_PRODUCER_PROFILE}
  query FetchProducerProfile($_partition: String!) {
    producerProfile( query: { _partition: $_partition } ){
     ...CoreProducerProfile
    }
  }
`;




  //MEDIA GALLERY
  export const GET_MEDIA_GALLERY = gql`
      ${CORE_MEDIA_GALLERY}
      query FetchMediaGallery($producer_id: String!) {
        mediaGallery( query: { _producer_id: $producer_id } ){
          ...CoreMediaGallery
        }
      }
  `;
  export const UPDATE_MEDIA_GALLERY = gql`
  ${CORE_MEDIA_GALLERY}
  mutation UpdateMediaGallery($data: MediaGalleryUpdateInput!, $producer_id: String!) {
    updateOneMediaGallery(query: { _producer_id: $producer_id }, set: $data ) {
      ...CoreMediaGallery
    }
  }
  `;
  export const DELETE_ONE_MEDIA_GALLERY = gql`
  ${CORE_MEDIA_GALLERY}
  mutation MediaGallery($id: ObjectId!) {
    deleteOneMediaGallery ( query: { _id: $id } ){
      ...MediaGallery
    }
  }
  `;

  export const ADD_TO_MEDIA_GALLERY = gql`
  ${CORE_MEDIA_GALLERY}
  mutation insertOneMediaGallery($data: MedaiGalleryInsertInput!) {
    insertOneMediaGallery(data: $data) {
      ...CoreMediaGallery
    }
  }
  `;
  //MEDIA GALLERY
