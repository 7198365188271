
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRealmApp } from "../../RealmApp";
import { useNavore } from "../../hooks/useNavore";


import { ReactComponent as Arrow } from '../../svg/arrow.svg';
import { ReactComponent as Trash } from '../../svg/trash.svg';
import { ReactComponent as SearchIcon } from '../../svg/search.svg';
import { ReactComponent as Check } from '../../svg/check.svg';
import { ReactComponent as Chevron } from '../../svg/chevron.svg';

import { ReactComponent as Cat1 } from '../../svg/cats/1.svg';
import { ReactComponent as Cat2 } from '../../svg/cats/2.svg';
import { ReactComponent as Cat3 } from '../../svg/cats/3.svg';
import { ReactComponent as Cat4 } from '../../svg/cats/4.svg';
import { ReactComponent as Cat5 } from '../../svg/cats/5.svg';
import { ReactComponent as Cat6 } from '../../svg/cats/6.svg';
import { ReactComponent as Cat7 } from '../../svg/cats/7.svg';
import { ReactComponent as Cat8 } from '../../svg/cats/8.svg';




const FilterCheckBox = ({ label, index, changed }) => {

	let i = "check" + (index + 1)

	return (
		<div className="filtersL_tab_box">
			<div className="Acheck">
				<input className="Acheck_npt" type="checkbox" name={i} id={i} onChange={(e) => changed(e.target.value)} />
				<div className="Acheck_sq">
					<Check />
				</div>
				<label className="Acheck_lbl" htmlFor={i}>{label}</label>
			</div>
		</div>

	)
}
const FilterCheckList = ({  items, onChange }) => {

	return (
		<div className="filtersL_tab_hold">
			{items.map((item, index) => {

				return (<FilterCheckBox key={index} name={item} changed={() => onChange(item)}/>)
			})}
		</div>
	)
}


const FiltersCategoriesList = () => {
	let catetory_icons = [<Cat1/>, <Cat2 />, <Cat3/>, <Cat4/>, <Cat5/>, <Cat6/>, <Cat7/>, <Cat8 />]

	let items = [{ name: "Vegetables", items: ["Baby Arugula", "Lettuce", "Cabbage"] }, { name: "Fruit", items: ["Watermelon", "Canteplope"] }]


	const selected = (item) =>{
		console.log(item)
	}


	return (

		<>
			{items.map((item, index) => {

				return (<div key={index} className="filtersL_tab">
					<button className="filtersL_tab_click click">
						<div className="click_i">

							{catetory_icons[index]}

						</div>
						<div className="click_t">{item.name}</div>
						<div className="click_fill">
							<Check />
						</div>
						<div className="click_qty">All items</div>
						<div className="click_c">
							<Chevron />
						</div>
					</button>

					<FilterCheckList items={item.items} onChange={(val) => selected(val, item.name)}/>

				</div>)
			})}

		</>

	)

}

export default function Lfilters() {

	let { t } = useTranslation()

	useEffect(() => {


	}, [])


	const daysChanged = (day) => {
		console.log(day)

	}
	const searchChanged = (text) => {
		console.log(text)

	}
	const minRangeChangedTop = (e) => {
		console.log(e.target.value)
	}

	const maxRangeChangedTop = (e) => {
		console.log(e.target.value)

	}

	const minRangeChangedBottom = (e) => {
		console.log(e.target.value)

	}


	const maxRangeChangedBottom = (e) => {
		console.log(e.target.value)

	}


	const onSingleRangeChanged = (e) => {
		console.log(e.target.value)

	}
	
	const onSortChanged = (e) =>{
		console.log(e.target.value)

	}

	return (
		<div className="filtersL">
			<div className="filtersCloseP" data-text="Filters">
				<button className="filtersCloseP_i">
					<Arrow />
				</button>
				<div className="filtersCloseP_t">Filters</div>
			</div>
			<div className="filtersL_buttons">
				<button className="Abtn Abtn-l Abtn-inv">
					<div className="Abtn_i">
						<Trash />
					</div>
					<div className="Abtn_t">Delete Filters</div>
				</button>
				<button className="Abtn Abtn-l">
					<div className="Abtn_t">Apply filters</div>
				</button>
			</div>
			<div className="searchbarP">
				<form className="searchbarP_npt">
					<div className="searchbarP_npt_i">
						<SearchIcon />

					</div>
					<input className="searchbarP_npt_field" placeholder="Start typing a product or producer…" type="text"
						name="search" id="search" onChange={(e)=> searchChanged(e.target.value)} />
				</form>
			</div>
			<div className="filtersL_hold">
				<div className="filtersL_sort">
					<h4 className="filtersL_t">Sort results by</h4>
					<div className="Aradio">
						<input className="Aradio_npt" checked="checked" type="radio" name="sort" id="earliest" onChange={(e)=>
						onSortChanged(e)}/><div className="Aradio_ball"></div><label className="Aradio_lbl"
							htmlFor="earliest">Earliest arrival</label>
					</div>
					<div className="Aradio"><input className="Aradio_npt" type="radio" name="sort" id="minimum" />
						<div className="Aradio_ball"></div><label className="Aradio_lbl" htmlFor="minimum">Minimum order (low to
							high)</label>
					</div>
					<div className="Aradio"><input className="Aradio_npt" type="radio" name="sort" id="topsellers" />
						<div className="Aradio_ball"></div><label className="Aradio_lbl" htmlFor="topsellers">Top
							Sellers</label>
					</div>
				</div>
				<h4 className="filtersL_t filtersL_t-P">Product categories</h4>
				<FiltersCategoriesList />

				<div className="filtersL_range">
					<h3 className="filtersL_t">Producer order min</h3>
					<div className="Arange">
						<div className="Arange_top">
							<div className="Arange_top_track"></div>
							<input className="Arange_top_min" type="range" min="0" max="100" value="0" onChange={(e)=>
							minRangeChangedTop(e)}/>
							<input className="Arange_top_max" type="range" min="0" max="100" value="100" onChange={(e)=>
							maxRangeChangedTop(e)}/>

						</div>
						<div className="Arange_bottom">
							<div className="Arange_bottom_npt">
								<div className="npt npt_min">
									<div className="npt_lbl">From</div>
									<input className="npt_input" type="number" value="0" min="0" max="100" onChange={(e)=>
									minRangeChangedBottom(e)}/>
								</div>
								<div className="npt npt_max">
									<div className="npt_lbl">to</div>
									<input className="npt_input" type="number" value="100" min="0" max="100" onChange={(e)=>
									maxRangeChangedBottom(e)} />
								</div>
							</div>
							<button className="Arange_bottom_reset">Reset</button>
						</div>
					</div>
				</div>
				<div className="filtersL_rangesingle">
					<h3 className="filtersL_t">Distance</h3>
					<div className="Arangesingle">
						<div className="Arangesingle_top">
							<div className="Arangesingle_top_track"></div>
							<div className="Arangesingle_top_value">100</div>
							<input className="Arangesingle_top_min" type="range" min="0" max="100" value="100" onChange={(e)=>
							onSingleRangeChanged(e)}/>
						</div>
					</div>
				</div>
				<div className="filtersL_days">
					<h4 className="filtersL_t">Delivery days</h4><button className="filtersL_days_click click">
						<div className="click_t">Select your pick up days</div>
						<div className="click_fill">
							<Check />
						</div>
						<div className="click_c">
							<Chevron />
						</div>
						<div className="click_clear">

							<Chevron />
						</div>
					</button>

					<div className="filtersL_days_hold">
						<div className="filtersL_days_box">
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="monday" id="monday" onChange={(e)=>
								daysChanged(e, "monday")} /><div className="Acheck_sq">
									<Check />
								</div>
								<label className="Acheck_lbl" htmlFor="monday">Monday</label>
							</div>
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="tuesday" id="tuesday" onChange={(e)=>
								daysChanged(e, "tuesday")} />
								<div className="Acheck_sq">
									<Check />
								</div><label className="Acheck_lbl" htmlFor="tuesday">Tuesday</label>
							</div>
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="wednesday" id="wednesday" onChange={(e)=>
								daysChanged(e, "wednesday")} /><div className="Acheck_sq">
									<Check />
								</div><label className="Acheck_lbl" htmlFor="wednesday">Wednesday</label>
							</div>
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="thursday" id="thursday" onChange={(e)=>
								daysChanged(e, "thursday")} /><div className="Acheck_sq">
									<Check />
								</div><label className="Acheck_lbl" htmlFor="thursday">Thursday</label>
							</div>
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="friday" id="friday" onChange={(e)=>
								daysChanged(e, "friday")} /><div className="Acheck_sq">
									<Check />
								</div><label className="Acheck_lbl" htmlFor="friday">Friday</label>
							</div>
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="saturday" id="saturday" onChange={(e)=>
								daysChanged(e, "saturday")} /><div className="Acheck_sq">
									<Check />
								</div><label className="Acheck_lbl" htmlFor="saturday">Saturday</label>
							</div>
							<div className="Acheck">
								<input className="Acheck_npt" type="checkbox" name="sunday" id="sunday" onChange={(e)=>
								daysChanged(e, "sunday")} /><div className="Acheck_sq">
									<Check />
								</div><label className="Acheck_lbl" htmlFor="sunday">Sunday</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
